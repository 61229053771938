import { Box, Button, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Drawer from 'src/app/components/Drawer';

import { useAppSelector } from '../../../shared/hooks/redux';
import { paymentSelector } from '../../../shared/store/Pagamento/payment.selectors';
import { setTipo, setValor } from '../../../shared/store/Pagamento/payment.slice';

import AdicionarSaldo from './AdicionarSaldo';
import { Pagamento } from './Pagamento/Pagamento';
import PagamentoEfetuado from './PagamentoEfetuado';
import PagamentoRecusado from './PagamentoRecusado';
import SelecionarTipoPagamento from './SelecionarTipoPagamento';

interface Props {
  open: boolean;
  onClose: () => void;
}

interface TabSelectorProps {
  activeStep: number;

  setActiveStep: Dispatch<SetStateAction<number>>;
  onCloseDrawer: () => void;
}

const TabSelector = ({ activeStep, setActiveStep, onCloseDrawer }: TabSelectorProps) => {
  const dispatch = useDispatch();
  const { tipo: tipoPagamento, valor } = useAppSelector(paymentSelector);

  useEffect(() => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) return prevActiveStep;
      return prevActiveStep + 1;
    });
  }, [setActiveStep, tipoPagamento]);

  const adicionarSaldo = useCallback(
    (saldo: number | '') => {
      dispatch(setValor(saldo ? saldo : 0));
    },
    [dispatch]
  );

  const onPaymentFalied = useCallback(() => setActiveStep(3), [setActiveStep]);
  const onPaymentComplete = useCallback(() => setActiveStep(4), [setActiveStep]);

  if (activeStep === 0) return <AdicionarSaldo saldo={valor} adicionarSaldo={adicionarSaldo} />;

  if (activeStep === 1) return <SelecionarTipoPagamento />;

  if (activeStep === 2 && tipoPagamento !== null) {
    return (
      <Pagamento
        tipoPagamento={tipoPagamento}
        onPaymentCompleted={onPaymentComplete}
        onPaymentFailed={onPaymentFalied}
      />
    );
  }
  if (activeStep === 3) return <PagamentoRecusado onPaymentFailed={() => setActiveStep(2)} />;

  if (activeStep === 4) return <PagamentoEfetuado onPaymentCompleted={onCloseDrawer} />;
  return null;
};

const DrawerAdicionarSaldo = ({ open, onClose }: Props) => {
  const [completed, setCompleted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { valor } = useAppSelector(paymentSelector);

  const dispatch = useDispatch();

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const onCloseDrawer = useCallback(() => {
    onClose();
    setActiveStep(0);
    dispatch(setValor(0));
    dispatch(setTipo(null));
  }, [dispatch, onClose]);

  useEffect(() => {
    if (valor) setCompleted(true);
  }, [valor]);

  useEffect(() => {
    return () => {
      setActiveStep(0);
      dispatch(setValor(0));
      dispatch(setTipo(null));
    };
  }, [dispatch]);

  return (
    <Drawer
      variant="persistent"
      title="Adicionar saldo"
      open={open}
      onClose={onCloseDrawer}
      onClick={(e) => e.stopPropagation()}
    >
      <Box p={3} flex={1} display="flex" flexDirection="column" justifyContent="space-between">
        <TabSelector
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          onCloseDrawer={onCloseDrawer}
        />
        {activeStep === 0 && (
          <Box pt={2} justifyContent="flex-end">
            <Button fullWidth variant="contained" onClick={handleNext} disabled={!completed}>
              <Typography textTransform="none">Próximo</Typography>
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerAdicionarSaldo;
