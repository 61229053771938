export const BASE_CONFIG = {
  Enviroment: process.env.NODE_ENV,
  App: {
    Version: process.env.REACT_APP_VERSION,
    Name: process.env.REACT_APP_NAME
  },
  Api: {
    BaseUrl: process.env.REACT_APP_BASE_API_URL,
    HubUrl: process.env.REACT_APP_BASE_NOTIFICATION_URL
  },
  AppInsights: {
    ConnectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING
  },
  Auth: {
    TenantHeader: process.env.REACT_APP_BASE_API_URL
  },
  Adyen: {
    Environment: process.env.REACT_APP_ADYEN_ENVIROMENT,
    ClientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY
  }
} as const;
