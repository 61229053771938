import { Box, Typography } from '@mui/material';

import { Carteira as CarteiraModel } from '../../models/Carteira.model';
import { Colors } from '../../shared/colors';

import ErrorCarteira from './Carteira/ErrorCarteira';
import Currency from './Currency';

export interface CardCarteiraProps {
  carteira: CarteiraModel;
}

export default function CardCarteira({ carteira }: CardCarteiraProps) {
  return (
    <Box
      sx={{
        backgroundColor: carteira.saldoBloqueado ? Colors.error.red : Colors.secondary,
        borderRadius: '0.5rem',
        padding: 0.25
      }}
    >
      <Box sx={{ backgroundColor: Colors.secondary }} padding={3} borderRadius={1}>
        <Typography color={Colors.white}>Saldo Disponível</Typography>
        <Typography color={Colors.white} variant="h5">
          <Currency value={carteira.saldo} />
        </Typography>
      </Box>
      {carteira.saldoBloqueado && (
        <ErrorCarteira message="Conta Bloqueada! Entre em contato com a Administração." />
      )}
    </Box>
  );
}
