import { Routes as ReactRouterRoutes, Route } from 'react-router-dom';

import CadastroPFController from 'src/app/features/cadastrar-usuario/CadastroPF/CadastroPFController';
import CadastroPJController from 'src/app/features/cadastrar-usuario/CadastroPJ/CadastroPJController';

import Cadastro from '../app/pages/Cadastro';

export const CadastroRoutes = () => {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<Cadastro />} />
      <Route path="/pf" element={<CadastroPFController />} />
      <Route path="/pj" element={<CadastroPJController />} />
    </ReactRouterRoutes>
  );
};
