import { Box } from '@mui/material';

import { PagamentoAdyen } from '../PagamentoAdyen/PagamentoAdyen';

interface Props {
  pagamentoId?: string;
  onPaymentFailed: () => void;
  onPaymentCompleted: () => void;
}

const PagamentoPorPix = ({ pagamentoId = '', onPaymentFailed, onPaymentCompleted }: Props) => {
  return (
    <Box display="flex" flex={1} padding={2}>
      <PagamentoAdyen
        pagamentoId={pagamentoId}
        type="pix"
        onPaymentFailed={onPaymentFailed}
        onPaymentCompleted={onPaymentCompleted}
      />
    </Box>
  );
};

export default PagamentoPorPix;
