import {
  AssociacaoFuncionario,
  ASSOCIACAO_FUNCIONARIO_DEFAULT
} from '../../../models/AssociacaoFuncionario.model';
import { Funcionario } from '../../../models/Funcionario.model';
import Drawer from '../../components/Drawer';

import FormAssociarCartao from './FormAssociarCartao';

interface Props {
  open: boolean;
  onClose: () => void;
  funcionario: Funcionario;
}

const DrawerAssociarCartao = ({ open, funcionario, onClose }: Props) => {
  const associacaoFuncionario: AssociacaoFuncionario = {
    ...ASSOCIACAO_FUNCIONARIO_DEFAULT,
    cpf: funcionario.cpf,
    nome: funcionario.nome,
    email: funcionario.email,
    associarCartao: true
  };

  return (
    <Drawer title="Associar cartão" open={open} onClose={onClose}>
      <FormAssociarCartao onClose={onClose} associacaoFuncionario={associacaoFuncionario} />
    </Drawer>
  );
};

export default DrawerAssociarCartao;
