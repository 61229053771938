import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Colors } from '../../../shared/colors';

export const CustomLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  padding-bottom: 1rem;

  &.active {
    font-weight: 600;
    padding-bottom: 0.75rem;
    display: inline-block;
    border-bottom: solid 0.25rem white;
  }

  @media (max-width: 600px) {
    color: ${Colors.primary.main};
    &.active {
      border-bottom: solid 0.25rem ${Colors.primary.main};
    }
  }
`;
