import { configureStore } from '@reduxjs/toolkit';

import adyenPaymentSlice from './Pagamento/adyenPayment.slice';
import { paymentSlice } from './Pagamento/payment.slice';

/* const isSerializable = (value: any) =>
  Iterable.isIterable(value) || isPlain(value);

const getEntries = (value: any) =>
  Iterable.isIterable(value) ? value.entries() : Object.entries(value);

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  isSerializable,
  getEntries,
});
 */

const store = configureStore({
  reducer: {
    adyenPayment: adyenPaymentSlice.reducer,
    payment: paymentSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
