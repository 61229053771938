import * as yup from 'yup';

import { validarCPF } from '../shared/functions/validarCpf';

export interface Cartao {
  numeroCartao?: string;
}

export type AssociacaoFuncionario = {
  cpf: string;
  nome: string;
  email: string;

  associarCartao: boolean;
} & Cartao;

export const CARTAO_DEFAULT: Cartao = {
  numeroCartao: ''
};

export const ASSOCIACAO_FUNCIONARIO_DEFAULT: AssociacaoFuncionario = {
  ...CARTAO_DEFAULT,
  cpf: '',
  nome: '',
  email: '',

  associarCartao: false
};

const MSG_REQUIRED = 'Campo obrigatório';

export const cartaoFormModelSchema: yup.ObjectSchema<Cartao> = yup.object().shape({
  numeroCartao: yup.string().required(MSG_REQUIRED)
});

export const associacaoFuncionarioFormModelSchema: yup.ObjectSchema<AssociacaoFuncionario> = yup
  .object()
  .shape({
    nome: yup.string().required(MSG_REQUIRED),
    email: yup.string().email('Email inválido').required(MSG_REQUIRED),
    cpf: yup
      .string()
      .required(MSG_REQUIRED)
      .test('Teste documento', 'Documento inválido', (value) => {
        if (!value) return false;
        return validarCPF(value);
      })
      .transform((val) => val.replace(/[^\d]+/g, '')),

    associarCartao: yup.boolean().required(MSG_REQUIRED),
    numeroCartao: yup.string().when('associarCartao', ([required], schema) => {
      if (required) return schema.required(MSG_REQUIRED);
      return schema.optional();
    })
  });
