import { mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel
} from '@microsoft/signalr';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import LinkPagamentoService from 'src/services/LinkPagamento.service';

import PicPay from '../../../../assets/picpay.svg';
import { PicpayProps } from '../../../../models/Picpay';
import PagamentoService from '../../../../services/Pagamento.service';
import { BASE_CONFIG } from '../../../../shared/baseConfig';
import { Colors } from '../../../../shared/colors';
import { useAppSelector } from '../../../../shared/hooks/redux';
import { paymentSelector } from '../../../../shared/store/Pagamento/payment.selectors';
import { useMsalService } from '../../authentication/services/msal.service';

interface StepProps {
  step: number;
}

const Step = ({ step }: StepProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={Colors.secondary}
      borderRadius="1rem"
      minWidth="1.5rem"
      height="1.5rem"
    >
      <Typography color="white" fontSize="0.8rem" fontWeight="bold">
        {step}
      </Typography>
    </Box>
  );
};

interface InstrucaoPagamentoProps {
  step: number;
  instrucao: string;
}

const InstrucaoPagamento = ({ step, instrucao }: InstrucaoPagamentoProps) => {
  return (
    <Box display="flex" gap={1} my={1}>
      <Step step={step} />
      <Typography color="secondary.dark">{instrucao}</Typography>
    </Box>
  );
};

interface Props {
  pagamentoId?: string;
  onPaymentFailed: () => void;
  onPaymentCompleted: () => void;
}

interface RetornoPicPay {
  sucesso: boolean;
  idSolicitacao?: string;
  idTransacao: string;
  valor: number;
}

const PagamentoPorPicPay = ({ pagamentoId = '', onPaymentCompleted, onPaymentFailed }: Props) => {
  const payment = useAppSelector(paymentSelector);
  const { getAccessToken } = useMsalService();
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [propsPagamento, setPropsPagamento] = useState<PicpayProps | null>(null);

  useEffect(() => {
    async function buildConnection() {
      const token = await getAccessToken();
      const query = pagamentoId ? `?id_solicitacao=${pagamentoId}` : '';

      return new HubConnectionBuilder()
        .configureLogging(LogLevel.Debug)
        .withUrl(BASE_CONFIG.Api.HubUrl + '/hub/pagamento-hub' + query, {
          accessTokenFactory: getAccessToken,
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .withAutomaticReconnect()
        .build();
    }

    buildConnection().then(setConnection);
  }, [getAccessToken, pagamentoId]);

  useEffect(() => {
    if (!connection) return;
    connection
      .start()
      .then(() => {
        connection.on('PagamentoRecebido', (message: RetornoPicPay) => {
          if (message.idSolicitacao && message.idSolicitacao !== pagamentoId) return;
          if (message.sucesso) onPaymentCompleted();
          else onPaymentFailed();
        });
      })
      .catch((error: any) => console.log(error));

    return () => {
      connection.stop();
    };
  }, [connection, onPaymentCompleted, onPaymentFailed, pagamentoId]);

  const copyToClipboard = () => {
    if (!propsPagamento) return;
    navigator.clipboard.writeText(propsPagamento.linkPagamento);
  };

  useEffect(() => {
    const valor = payment.valor;

    if (pagamentoId) {
      LinkPagamentoService.pagarPicpay(pagamentoId).then(setPropsPagamento);
    } else {
      PagamentoService.pagarPicpay(valor).then(setPropsPagamento);
    }
  }, [payment.valor, pagamentoId]);

  if (!propsPagamento) return null;

  return (
    <Box p={1} flex={1} display="flex" flexDirection="column" justifyContent="space-around">
      <Box display="flex" flexDirection="column">
        <Box width="10rem" alignSelf="center" mb={3.5}>
          <img src={PicPay} alt="picpay-logo" />
        </Box>

        <Typography variant="h6" align="center" color="secondary.dark" mb={2}>
          Para pagar com o PicPay, siga os passos abaixo:
        </Typography>

        <InstrucaoPagamento step={1} instrucao="Abra o aplicativo PicPay pelo seu celular." />
        <InstrucaoPagamento step={2} instrucao="Escaneie o QRCode ao lado." />
        <InstrucaoPagamento
          step={3}
          instrucao="Confira as informações no aplicativo e finalize o pagamento."
        />
        <InstrucaoPagamento
          step={4}
          instrucao="Não feche ou atualize essa tela, você será redirecionado automaticamente
        para confirmação de compra."
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth={250}
        alignSelf="center"
      >
        <img src={propsPagamento.qrCode} alt="qrCode" width="75%" />

        <Box
          p={1}
          gap={1}
          bgcolor={Colors.primary.light}
          display="flex"
          justifyContent="center"
          borderRadius={1}
          sx={{ cursor: 'pointer' }}
          onClick={copyToClipboard}
        >
          <Icon path={mdiContentCopy} size={1} color={Colors.primary.main} />
          <Typography color="primary">Copiar link de pagamento</Typography>
        </Box>
      </Box>

      <Box justifySelf="end">
        <Typography color="error">Atenção!</Typography>
        <Typography>
          O processo de troca com PicPay, só poderá ser feito presencialmente nas agências.
        </Typography>
        <Typography>
          O reembolso do valor só é possível em dinheiro e por isso o cancelamento só poderá ser
          feito presencialmente nas agências.
        </Typography>
      </Box>
    </Box>
  );
};

export default PagamentoPorPicPay;
