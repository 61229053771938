import { UsuarioPF } from '@vabembarqueja/embarque-ja-shared-components';
import { addDays } from 'date-fns';

import { APIPaginatedRequest } from '../models/APIPaginatedRequest';
import { APIPaginatedResponse } from '../models/APIPaginatedResponse';
import { APIResponse } from '../models/ApiResponse.model';
import { AssociacaoFuncionario, Cartao } from '../models/AssociacaoFuncionario.model';
import { FiltroTransacoesUsuarios } from '../models/FiltroTransacoes.model';
import { FiltroUsuario } from '../models/FiltroUsuario.model';
import { ALteracaoStatus, Funcionario } from '../models/Funcionario.model';
import { Transacao } from '../models/Transacao.model';
import { Transferencias } from '../models/Transferencia.model';

import Service from './Service';

class FuncionarioService extends Service {
  static async listar(data: APIPaginatedRequest<FiltroUsuario>, signal?: AbortSignal) {
    return this.Http.post<APIResponse<APIPaginatedResponse<Funcionario>>>(
      '/funcionario/listar',
      data,
      { signal }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }
  static async listarTransacoes(
    data: APIPaginatedRequest<FiltroTransacoesUsuarios>,
    signal?: AbortSignal
  ) {
    if ('dataFinal' in data.search && data.search.dataFinal)
      data.search = {
        ...data.search,
        dataFinal: addDays(data.search.dataFinal, 1)
      };
    return this.Http.post<APIResponse<APIPaginatedResponse<Transacao>>>(
      '/funcionario/transacoes',
      data,
      { signal }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async buscar(cpf: string) {
    return this.Http.get<APIResponse<UsuarioPF | null>>(`/funcionario/${cpf.replace(/\D+/g, '')}`)
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async criar(data: AssociacaoFuncionario) {
    return this.Http.post('/funcionario/criar', data);
  }

  static async alterarStatus(data: ALteracaoStatus) {
    return this.Http.put('/funcionario/status', data);
  }

  static async associarCartao(data: AssociacaoFuncionario) {
    return this.Http.post('/funcionario/associar-cartao', data);
  }

  static async desassociarCartao(data: Cartao) {
    return this.Http.post('/funcionario/desassociar-cartao', data);
  }

  static async carregarCartao(data: Transferencias) {
    const funcionarios = data.funcionarios.map((func) => ({
      cpf: func.cpf,
      valor: func.valor
    }));
    return this.Http.post('/funcionario/carregar', { funcionarios });
  }

  static async descarregarCartao(data: Transferencias) {
    const funcionarios = data.funcionarios.map((func) => ({
      cpf: func.cpf,
      valor: func.valor
    }));
    return this.Http.post('/funcionario/descarregar', { funcionarios });
  }
}

export default FuncionarioService;
