import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TipoPagamento } from '../../../models/TipoPagamento';

interface PaymentProps {
  tipo: TipoPagamento | null;
  valor: number;
}

const INITIAL_STATE: PaymentProps = {
  valor: 0,
  tipo: null
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: INITIAL_STATE,
  reducers: {
    setValor: (state, action: PayloadAction<number>) => {
      const valor = action.payload;
      state.valor = valor;
    },
    setTipo: (state, action: PayloadAction<TipoPagamento | null>) => {
      const tipo = action.payload;
      state.tipo = tipo;
    }
  }
});

export const { setValor, setTipo } = paymentSlice.actions;

export default paymentSlice;
