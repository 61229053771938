export enum TipoUsuario {
  PESSOA_FISICA = 1,
  PESSOA_JURIDICA = 2
}

export enum SolicitacaoState {
  NAO_SOLICITADO = 1,
  PENDENTE = 2,
  AVALIADO = 3
}

export interface UsuarioPosPagoState {
  posPagoAtivo: boolean;
  solicitacao: SolicitacaoState;
}

export interface UsuarioIncompleto {
  nome: string;
  cpf: string;
  email: string;
}
