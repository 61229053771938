import { mdiFilterVariant } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Grid, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { BilhetePassagem } from 'src/models/LinkPagamento';

import { useUserContext } from '../../../contexts/User.context';
import { TipoTransacaoLista } from '../../../models/Carteira.model';
import { FiltroTransacoes } from '../../../models/FiltroTransacoes.model';
import {
  TIPO_TRANSACAO_PF,
  TIPO_TRANSACAO_PJ,
  TIPO_TRANSACAO_PJ_USER,
  Transacao
} from '../../../models/Transacao.model';
import { TipoUsuario } from '../../../models/Usuario';
import { CarteiraService } from '../../../services';
import { Colors } from '../../../shared/colors';
import useInfiniteScroll from '../../../shared/hooks/infiniteScroll';
import CardTransacao from '../../components/CardTransacao';
import DrawerDetalhesViagem from '../detalhes-viagem/DrawerDetalhesViagem';
import DrawerFiltrarTransacao from '../filtrar-transacoes/DrawerFiltrarTransacaoUsuarios';

interface Props {
  usuarioAssociadoEmpresa: boolean;
}

export interface ListaTransacoesRef {
  atualizarTransacoes: () => void;
}

const ListarTransacoes = forwardRef<ListaTransacoesRef, Props>(
  ({ usuarioAssociadoEmpresa }, ref) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [filtros, setFiltros] = useState<FiltroTransacoes>();
    const [tab, setTab] = useState(TipoTransacaoLista.PESSOA_FISICA);
    const [loadFunction, setLoadFunction] = useState(() => CarteiraService.getTransacoesCarteiraPF);
    const [bilhetesPassagem, setBilhetespassagem] = useState<BilhetePassagem[] | null>(null);

    const { userClaim } = useUserContext();
    const usuarioPJ = userClaim === TipoUsuario.PESSOA_JURIDICA;

    const closeDrawerFiltro = (filtroTransacoes: FiltroTransacoes) => {
      setOpenDrawer(false);
      setFiltros(filtroTransacoes);
    };

    const {
      refresh,
      hasNext,
      loading,
      lastElementRef,
      items: transacoes
    } = useInfiniteScroll<Transacao, FiltroTransacoes>({
      search: filtros,
      load: loadFunction
    });

    useImperativeHandle(ref, () => ({ atualizarTransacoes: refresh }), [refresh]);

    useEffect(() => {
      const loadFunctionPF =
        tab === TipoTransacaoLista.PESSOA_FISICA
          ? CarteiraService.getTransacoesCarteiraPF
          : CarteiraService.getTransacoesCarteiraPJ;

      setLoadFunction(() => (usuarioPJ ? CarteiraService.getTransacoes : loadFunctionPF));
    }, [tab, usuarioPJ]);

    const carregandoItens = (loading && hasNext) || loading;

    const filtroDrawerPF =
      tab === TipoTransacaoLista.PESSOA_FISICA ? TIPO_TRANSACAO_PF : TIPO_TRANSACAO_PJ_USER;
    const filtrosDrawer = usuarioPJ ? TIPO_TRANSACAO_PJ : filtroDrawerPF;

    useEffect(() => {
      setFiltros({});
    }, [tab]);

    return (
      <>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        >
          <Grid item xs={12} sm={8} my={{ xs: 1, sm: 0 }}>
            {usuarioPJ || !usuarioAssociadoEmpresa ? (
              <Typography variant="h5" color={Colors.secondary} display="inline" fontWeight={700}>
                Transações
              </Typography>
            ) : (
              <Tabs value={tab} variant="fullWidth">
                <Tab
                  label="Transações pessoais"
                  value={TipoTransacaoLista.PESSOA_FISICA}
                  onClick={() => setTab(TipoTransacaoLista.PESSOA_FISICA)}
                />
                <Tab
                  label="Transações empresa"
                  value={TipoTransacaoLista.PESSOA_JURIDICA}
                  onClick={() => setTab(TipoTransacaoLista.PESSOA_JURIDICA)}
                />
              </Tabs>
            )}
          </Grid>

          <Grid
            item
            sm={4}
            gap={1}
            xs={12}
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-end"
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpenDrawer(true)}
            alignSelf={{ xs: 'flex-end', sm: 'center' }}
          >
            <Typography fontWeight="bold" color={Colors.primary.main}>
              Filtrar
            </Typography>
            <Icon path={mdiFilterVariant} size={1} color={Colors.primary.main} />
          </Grid>
        </Grid>

        {transacoes.map((transacao, index, arr) => {
          const lastElement = arr.length === index + 1;
          const ref = lastElement && hasNext ? lastElementRef : null;
          return (
            <div ref={ref} key={transacao.idTransacao}>
              <CardTransacao
                onClick={() => {
                  setBilhetespassagem(transacao.bilhetes ?? null);
                }}
                transacao={transacao}
              />
            </div>
          );
        })}

        <Grid item xs={12}>
          {carregandoItens && (
            <Grid container mt={2} gap={2}>
              {Array(5)
                .fill(1)
                .map((_item, index) => index)
                .map((value) => (
                  <Skeleton key={value} variant="rounded" width="100%" height={70} />
                ))}
            </Grid>
          )}
          <Typography
            variant="body1"
            fontWeight={500}
            color={Colors.gray1}
            textAlign="center"
            mt={3}
          >
            {!hasNext && !loading && 'Não há dados a serem exibidos'}
          </Typography>
        </Grid>

        <DrawerFiltrarTransacao
          open={openDrawer}
          onClose={closeDrawerFiltro}
          tipoTransacoes={filtrosDrawer}
        />
        <DrawerDetalhesViagem
          bilhetes={bilhetesPassagem}
          onClose={() => setBilhetespassagem(null)}
        />
      </>
    );
  }
);

ListarTransacoes.displayName = 'ListaTransacoes';
export default ListarTransacoes;
