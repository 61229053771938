import { Box } from '@mui/material';

import { Carteira as CarteiraModel } from '../../../models/Carteira.model';
import { Colors } from '../../../shared/colors';

import Cartao from './Cartao';
import ErrorCarteira from './ErrorCarteira';

interface PropsCarteira {
  carteira: CarteiraModel;
}

const Carteira = ({ carteira }: PropsCarteira) => {
  if (carteira.saldo < 0 || carteira.saldoBloqueado)
    return (
      <Box
        sx={{
          backgroundColor: Colors.error.red,
          borderRadius: '0.5rem',
          padding: 0.25
        }}
      >
        <Cartao carteira={carteira} />
        {carteira.saldo < 0 && <ErrorCarteira message="Conta bloqueada! Regularize o seu saldo." />}

        {carteira.saldoBloqueado && (
          <ErrorCarteira message="Conta Bloqueada! Entre em contato com a Administração." />
        )}
      </Box>
    );

  return <Cartao carteira={carteira} />;
};

export default Carteira;
