import { InteractionType } from '@azure/msal-browser';
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication
} from '@azure/msal-react';
import { Box, Container } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';

import CargaDistancia from 'src/app/features/carga-distancia/CargaDistancia';

import AppBar from '../app/components/AppBar';
import { MsalConfig } from '../app/features/authentication/config/msalConfig';
import EditarUsuario from '../app/features/editar-usuario/EditarUsuario';
import Extrato from '../app/pages/Extrato';
import Home from '../app/pages/Home';
import ListaUsuarios from '../app/pages/ListaUsuarios';
import NotFound from '../app/pages/NotFound';
import TransacoesUsuarios from '../app/pages/TransacoesUsuarios';

import { CadastroRoutes } from './cadastro.routes';

export const Routes = {
  Home: '/',
  Cadastro: {
    Principal: '/cadastro/*',
    Pf: '/cadastro/pf',
    Pj: '/cadastro/pj'
  } as const,
  Pagamento: {
    Principal: '/pagamento/:id'
  } as const,
  Conta: {
    Principal: '/minha-conta'
  } as const,
  Usuarios: {
    Principal: '/usuarios'
  } as const,
  Transacoes: {
    Principal: '/transacoes'
  } as const,
  Extrato: {
    Principal: '/extrato'
  } as const
} as const;

const AuthError: React.ElementType<MsalAuthenticationResult> = () => {
  const request = { scopes: MsalConfig.scopes };
  useMsalAuthentication(InteractionType.Redirect, request);
  return null;
};

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <AppBar />
      <Container maxWidth="lg" sx={{ height: 1 }}>
        {children}
      </Container>
    </Box>
  );
};

export const AuthenticationTemplate = ({ children }: PropsWithChildren) => {
  return (
    <MsalAuthenticationTemplate
      errorComponent={AuthError}
      interactionType={InteractionType.Redirect}
      authenticationRequest={{ scopes: MsalConfig.scopes }}
    >
      <Layout>{children}</Layout>
    </MsalAuthenticationTemplate>
  );
};

export const withoutAuthentication = (Component: () => JSX.Element) => {
  return (
    <UnauthenticatedTemplate>
      <Layout>
        <Component />
      </Layout>
    </UnauthenticatedTemplate>
  );
};

export const AppRoutes = () => {
  return (
    <ReactRoutes>
      <Route
        path={Routes.Home}
        element={
          <AuthenticationTemplate>
            <Home />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={Routes.Cadastro.Principal}
        element={
          <Layout>
            <CadastroRoutes />
          </Layout>
        }
      />
      <Route
        path={Routes.Pagamento.Principal}
        element={
          <Layout>
            <CargaDistancia />
          </Layout>
        }
      />
      <Route
        path={`${Routes.Conta.Principal}/*`}
        element={
          <AuthenticationTemplate>
            <EditarUsuario />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={`${Routes.Usuarios.Principal}/*`}
        element={
          <AuthenticationTemplate>
            <ListaUsuarios />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={`${Routes.Transacoes.Principal}/*`}
        element={
          <AuthenticationTemplate>
            <TransacoesUsuarios />
          </AuthenticationTemplate>
        }
      />
      <Route
        path={`${Routes.Extrato.Principal}/*`}
        element={
          <AuthenticationTemplate>
            <Extrato />
          </AuthenticationTemplate>
        }
      />

      <Route
        path="*"
        element={
          <AuthenticationTemplate>
            <NotFound />
          </AuthenticationTemplate>
        }
      />
    </ReactRoutes>
  );
};
