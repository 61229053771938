import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Currency from 'src/app/components/Currency';
import FeedbackDrawer from 'src/app/components/FeedbackDrawer';
import { LinkPagamento } from 'src/models/LinkPagamento';
import { TipoPagamento } from 'src/models/TipoPagamento';
import { Routes } from 'src/routes/index.routes';
import LinkPagamentoService from 'src/services/LinkPagamento.service';
import { Colors } from 'src/shared/colors';

import Error from '../../../assets/associar-error.svg';
import Sucess from '../../../assets/sucesso-pagamento.svg';
import { Pagamento } from '../adicionar-saldo/Pagamento/Pagamento';

import { ConfirmacaoPosPago } from './ConfirmacaoPosPago';
import { InformacoesBilhete } from './InformacoesBilhete';

const CargaDistancia = () => {
  const { id } = useParams<{ id: string }>();
  const [pagamento, setPagamento] = useState<LinkPagamento>();
  const [errorMSg, setErrorMsg] = useState('');
  const [completed, setCompleted] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    LinkPagamentoService.buscar(id).then((pagamento) => {
      setPagamento(pagamento);
      setCompleted(pagamento.pagamentoAutorizado);
    });
  }, [id]);

  if (!pagamento || !id) return null;

  const compraPassagem = pagamento.bilhetes && pagamento.bilhetes.length > 0;

  const navigateToHome = () => {
    navigate(Routes.Home);
  };

  if (completed)
    return (
      <Box width={0.4} display="flex" height={0.9} margin="auto">
        <FeedbackDrawer
          titulo={compraPassagem ? 'Compra concluída!' : 'Carga aprovada!'}
          subtitulo={
            compraPassagem ? 'Compra concluída com sucesso!' : 'Carga aprovada com sucesso!'
          }
          textoBotao="Fechar"
          imagemModal={Sucess}
          onClick={navigateToHome}
        />
      </Box>
    );

  if (errorMSg)
    return (
      <Box width={0.4} display="flex" height={0.9} margin="auto">
        <FeedbackDrawer
          titulo={compraPassagem ? 'Erro ao concluir a compra' : 'Erro ao realizar carga!'}
          subtitulo={errorMSg}
          textoBotao="Fechar"
          imagemModal={Error}
          onClick={navigateToHome}
        />
      </Box>
    );

  if (pagamento.solicitacaoExpirada)
    return (
      <Box p={4}>
        <Box my={4}>
          <Typography textAlign="center" variant="h4" color={Colors.primary.main} my={2}>
            Link expirado!
          </Typography>

          <Typography textAlign="center" mt={4}>
            A solicitação de carga para o link atual expirou.
          </Typography>
          <Typography textAlign="center" mb={2}>
            Por favor, solicite uma nova carga.
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <Box mt={8} width={0.5}>
            <img width="100%" src={Error} alt="pagamento_realizado" />
          </Box>
        </Box>
      </Box>
    );

  return (
    <Box p={4}>
      <Box my={4}>
        <Typography textAlign="center" variant="h4" color={Colors.primary.main} my={2}>
          {compraPassagem ? 'Compra de passagem' : 'Solicitação de carga'}
        </Typography>

        <Typography textAlign="center" mt={2}>
          Existe
          {compraPassagem ? ' uma compra de passagem ' : ' uma solicitação de carga '}
          em aberto!
        </Typography>

        <Typography textAlign="center" mb={2}>
          Prossiga com o pagamento caso as informações estejam corretas.
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Box width={0.6}>
          <Box display="flex" justifyContent="space-between">
            <Typography display="inline" color={Colors.secondary}>
              Valor da {compraPassagem ? ' compra' : ' carga'}
            </Typography>
            <Typography display="inline" variant="h6" color={Colors.primary.dark}>
              <Currency value={pagamento.valor} />
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Typography display="inline" color={Colors.secondary}>
              Usuário
            </Typography>
            <Typography display="inline" variant="h6" color={Colors.primary.dark}>
              {pagamento.usuarioNome}
            </Typography>
          </Box>

          {compraPassagem && (
            <Box display="flex" flexDirection="column">
              <Typography my={1} variant="h6" color={Colors.primary.main}>
                Bilhetes
              </Typography>
              {pagamento.bilhetes &&
                pagamento.bilhetes.map((bilhete) => (
                  <InformacoesBilhete
                    bilhete={bilhete}
                    key={`${bilhete.dataEmbarque}-${bilhete.linha}-${bilhete.poltrona}`}
                  />
                ))}
            </Box>
          )}

          <Box my={4}>
            {pagamento.tipoPagamento === TipoPagamento.PJPosPago || compraPassagem ? (
              <ConfirmacaoPosPago
                idSolicitacaoLink={id}
                onError={setErrorMsg}
                onSucess={() => setCompleted(true)}
              />
            ) : (
              <Pagamento
                pagamentoId={id}
                tipoPagamento={pagamento.tipoPagamento}
                onPaymentCompleted={() => setCompleted(true)}
                onPaymentFailed={() => setErrorMsg('O pagamento não foi aprovado')}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CargaDistancia;
