import { TipoPagamento } from './TipoPagamento';

export interface Pagamento {
  valor: number;
  tipoPagamento: TipoPagamento;
}

export interface ThreeDSecureDetails {
  details: {
    threeDSResult: string;
  };
}

export enum AdyenPaymentStatus {
  Refused = 'Refused',
  Authorised = 'Authorised'
}
