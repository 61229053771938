import Icon from '@mdi/react';
import { Box, Typography } from '@mui/material';

import { Colors } from '../../shared/colors';
export interface ButtonProps {
  icon: string;
  text: string;
  onClick: () => void;
}

export default function ButtonTransferencia({ icon, text, onClick }: ButtonProps) {
  return (
    <Box
      gap={2}
      width={1}
      maxHeight={3}
      paddingX={2}
      paddingY={5}
      borderRadius={1}
      display="flex"
      alignItems="center"
      onClick={onClick}
      sx={{
        border: `1px solid ${Colors.primary.main}`,
        cursor: 'pointer'
      }}
    >
      <Box borderRadius={1} padding={1} sx={{ backgroundColor: Colors.gray3 }}>
        <Icon path={icon} size={1.5} color={Colors.primary.dark} />
      </Box>
      <Typography fontWeight="bold" color={Colors.primary.main}>
        {text}
      </Typography>
    </Box>
  );
}
