import { AppRoutes } from '../../routes/index.routes';

function App() {
  /*   const appInsights = useAppInsightsContext();
  const metricData = {
    average: 60,
    name: 'React Component Engaged Time (seconds)',
    sampleCount: 1
  };
  const additionalProperties = { 'Component Name': 'App' };
  appInsights.trackMetric(metricData, additionalProperties); */

  return <AppRoutes />;
}

export default App;
