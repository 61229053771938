import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserContext } from '../../contexts/User.context';
import { Carteira as CarteiraModel } from '../../models/Carteira.model';
import { Routes } from '../../routes/index.routes';
import { CarteiraService } from '../../services';
import { Colors } from '../../shared/colors';
import usePageTitle from '../../shared/hooks/usePageTitle';
import Loading from '../components/Loading';
import DrawerAdicionarSaldo from '../features/adicionar-saldo/DrawerAdicionarSaldo';
import ListarTransacoes, {
  ListaTransacoesRef
} from '../features/listar-transacoes/ListarTransacoes';
import ListarCartoes from '../features/ListarCartoes';

const Home = () => {
  usePageTitle('Embarque já');
  const navigate = useNavigate();
  const { loading, user } = useUserContext();
  const listaTransacoesRef = useRef<ListaTransacoesRef>(null);
  const [carteiras, setCarteiras] = useState<CarteiraModel[]>([]);
  const [drawerAdicaoValorOpen, setDrawerAdicaoValorOpen] = useState(false);

  const fecharDrawerAdicaoValor = () => {
    listaTransacoesRef.current?.atualizarTransacoes();
    CarteiraService.getCarteiras().then(setCarteiras);
    setDrawerAdicaoValorOpen(false);
  };

  useEffect(() => {
    if (!user) return;
    if (!('rg' in user) && !('cnpj' in user)) navigate(Routes.Cadastro.Pf, { state: user });
    else CarteiraService.getCarteiras().then(setCarteiras);
  }, [navigate, user]);

  if (loading) return <Loading fullscreen show={loading} />;
  const carteiraBloqueada = carteiras.some((carteira) => carteira.saldoBloqueado);
  return (
    <>
      <Grid container spacing={2} paddingY={2}>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={6} sm={8} md={9} lg={10}>
            <Typography variant="h5" color={Colors.secondary} display="inline" fontWeight={700}>
              Carteira
            </Typography>
          </Grid>

          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Button
              disabled={carteiraBloqueada}
              fullWidth
              variant="contained"
              onClick={() => setDrawerAdicaoValorOpen(true)}
            >
              <Typography textTransform="none" lineHeight={1}>
                Recarregar saldo
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ListarCartoes carteiras={carteiras} />
        </Grid>
        <Grid item xs={12} my={1}>
          <ListarTransacoes
            ref={listaTransacoesRef}
            usuarioAssociadoEmpresa={carteiras.length > 1}
          />
        </Grid>
      </Grid>

      {drawerAdicaoValorOpen && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width={1}
          height={1}
          zIndex={1250}
          onClick={fecharDrawerAdicaoValor}
          sx={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <DrawerAdicionarSaldo open={drawerAdicaoValorOpen} onClose={fecharDrawerAdicaoValor} />
        </Box>
      )}
    </>
  );
};

export default Home;
