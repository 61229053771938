import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';

import {
  FiltroUsuario,
  filtroUsuarioFormModelSchema,
  FILTRO_USUARIO_DEFAULT
} from '../../../models/FiltroUsuario.model';
import { Input } from '../../components/Form/Input';
import { PatternInput } from '../../components/Form/PatternInput';
import { RadioInput } from '../../components/Form/RadioInput';
import Loading from '../../components/Loading';

interface Props {
  onClose: (filtroUsuarios: FiltroUsuario) => void;
  onChangeFilter: (filtroUsuarios: FiltroUsuario) => void;
  filtroUsuarios: FiltroUsuario;
}

const FormFiltrarUsuario = ({ onClose, onChangeFilter, filtroUsuarios }: Props) => {
  const loading = false;
  const form = useForm<FiltroUsuario>({
    defaultValues: filtroUsuarios,
    resolver: yupResolver(filtroUsuarioFormModelSchema)
  });

  const onValid = (filtroUsuarios: FiltroUsuario) => {
    const filtroSemValoresVazios = Object.fromEntries(
      Object.entries(filtroUsuarios).filter(([_, v]) => v)
    );
    onClose(filtroSemValoresVazios);
  };

  const onInvalid: SubmitErrorHandler<FiltroUsuario> = (err) => {
    console.log(err);
  };

  const limparFiltro = () => {
    onChangeFilter(FILTRO_USUARIO_DEFAULT);
    form.reset(FILTRO_USUARIO_DEFAULT);
  };

  return (
    <FormProvider {...form}>
      <Loading fullscreen show={loading} />
      <Box display="flex" flexDirection="column" alignItems="space-between">
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12}>
            <Input
              name="nome"
              control={form.control}
              label="Nome"
              placeholder="Nome"
              inputProps={{ maxLength: 150 }}
            />
          </Grid>
          <Grid item xs={12}>
            <PatternInput
              label="CPF"
              name="cpf"
              control={form.control}
              placeholder="CPF"
              format="###.###.###-##"
            />
          </Grid>

          <Grid item xs={12}>
            <PatternInput
              label="Número do cartão"
              name="numeroCartao"
              control={form.control}
              placeholder="Número do cartão"
              format="############"
            />
          </Grid>
          <Grid item xs={12}>
            <RadioInput
              name="status"
              control={form.control}
              label="Situação"
              values={[
                {
                  value: 1,
                  label: 'Ambos'
                },
                {
                  value: 2,
                  label: 'Ativo'
                },
                {
                  value: 3,
                  label: 'Inativo'
                }
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} padding={3} alignContent="flex-end">
          <Grid item xs={12}>
            <Button fullWidth variant="outlined" onClick={limparFiltro}>
              <Typography textTransform="none">Limpar filtro</Typography>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={form.handleSubmit(onValid, onInvalid)}>
              <Typography textTransform="none">Filtrar</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FormFiltrarUsuario;
