import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { useUserContext } from '../../../contexts/User.context';
import {
  FiltroTransacoes,
  filtroTransacoesFormModelSchema,
  FILTRO_TRANSACOES_DEFAULT
} from '../../../models/FiltroTransacoes.model';
import { TipoTransacao } from '../../../models/Transacao.model';
import { TipoUsuario } from '../../../models/Usuario';
import { AutoComplete } from '../../components/Form/AutoComplete';
import DatePicker from '../../components/Form/DatePicker';
import { Input } from '../../components/Form/Input';
import { PatternInput } from '../../components/Form/PatternInput';

type TipoTransacaoSelect = {
  label: string;
  value: TipoTransacao;
};

interface Props {
  onClose: (filtroTransacoes: FiltroTransacoes) => void;
  onChangeFilter: (filtroTransacoes: FiltroTransacoes) => void;
  tiposTransacao: TipoTransacaoSelect[];
  filtroTransacoes: FiltroTransacoes;
  permitirFiltrarUsuarios?: boolean;
}

const FiltrarTransacoes = ({
  onClose,
  onChangeFilter,
  tiposTransacao,
  filtroTransacoes,
  permitirFiltrarUsuarios = false
}: Props) => {
  const { userClaim } = useUserContext();
  const usuarioPJ = userClaim === TipoUsuario.PESSOA_JURIDICA;

  const mostrarCampoUsuario = permitirFiltrarUsuarios && usuarioPJ;

  const form = useForm<FiltroTransacoes>({
    defaultValues: filtroTransacoes ?? FILTRO_TRANSACOES_DEFAULT,
    resolver: yupResolver(filtroTransacoesFormModelSchema)
  });

  const filtrarTransacoes = (filtros: FiltroTransacoes) => {
    const filtroSemValoresVazios = Object.fromEntries(
      Object.entries(filtros).filter(([_, v]) => v)
    );
    onClose(filtroSemValoresVazios);
  };

  const limparFiltros = () => {
    onChangeFilter(FILTRO_TRANSACOES_DEFAULT);
    form.reset(FILTRO_TRANSACOES_DEFAULT);
  };

  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" alignItems="space-between">
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12}>
            <AutoComplete
              name="tipoTransacao"
              control={form.control}
              label="Tipo de transação"
              placeholder="Selecione o tipo de transação"
              options={tiposTransacao}
            />
          </Grid>

          {mostrarCampoUsuario && (
            <Grid item xs={12}>
              <Input
                name="usuario"
                label="Usuário"
                placeholder="Nome de usuário"
                control={form.control}
                inputProps={{ maxLength: 150 }}
              />
            </Grid>
          )}

          {mostrarCampoUsuario && (
            <Grid item xs={12}>
              <PatternInput
                label="CPF"
                name="cpf"
                control={form.control}
                placeholder="CPF"
                format="###.###.###-##"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Input
              name="idAutorizacao"
              control={form.control}
              label="Número de autorização"
              placeholder="Número de autorização"
              inputProps={{ maxLength: 150 }}
            />
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              name="dataInicio"
              control={form.control}
              label="Data de início"
              placeholder="Data de início"
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              name="dataFinal"
              control={form.control}
              label="Data final"
              placeholder="Data final"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} padding={3} alignContent="flex-end">
          <Grid item xs={12}>
            <Button fullWidth variant="outlined" onClick={limparFiltros}>
              <Typography textTransform="none">Limpar filtros</Typography>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={form.handleSubmit(filtrarTransacoes, console.log)}
            >
              <Typography textTransform="none">Filtrar</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FiltrarTransacoes;
