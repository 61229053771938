import { Box, Button, Typography } from '@mui/material';

import ImagemSucesso from '../../../assets/sucesso-pagamento.svg';
import { Colors } from '../../../shared/colors';

interface Props {
  onPaymentCompleted: () => void;
}

const PagamentoEfetuado = ({ onPaymentCompleted }: Props) => {
  return (
    <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between">
      <Box
        display="flex"
        px={{ xs: 2, md: 5 }}
        py={{ xs: 5, md: 10 }}
        flexDirection="column"
        alignItems="center"
      >
        <Typography color="secondary" variant="h6" fontWeight="bold" align="center">
          Pagamento realizado com sucesso!
        </Typography>
        <Typography
          mt={2}
          variant="body1"
          textAlign="center"
          fontSize={{ sm: '1.5rem' }}
          color={Colors.gray1}
        >
          Seu pagamento foi confirmado, clique em fechar para voltar a tela inicial.
        </Typography>
      </Box>
      <Box display="flex" flex={1} px={{ xs: 2, md: 5 }} py={{ xs: 5, md: 10 }}>
        <img src={ImagemSucesso} alt="pagamento_realizado" />
      </Box>
      <Box display="flex" alignContent="flex-end">
        <Button fullWidth variant="contained" onClick={onPaymentCompleted}>
          <Typography textTransform="none">Fechar</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default PagamentoEfetuado;
