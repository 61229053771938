import { mdiAccount, mdiAccountGroup } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Colors } from '../../shared/colors';

interface CardSelecaoProps {
  tipo: 'Pessoa Física' | 'Pessoa Jurídica';
  onSelect: () => void;
}

const CardSelecao = ({ tipo, onSelect }: CardSelecaoProps) => {
  const icon = tipo === 'Pessoa Física' ? mdiAccount : mdiAccountGroup;

  return (
    <Grid
      item
      gap={2}
      flex={1}
      paddingY={4}
      paddingX={8}
      maxWidth={300}
      borderRadius={1}
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      boxShadow=" 0px 5px 20px rgba(0, 0, 0, 0.15)"
    >
      <Box
        p={2}
        borderRadius={1}
        display="flex"
        justifyContent="center"
        bgcolor={Colors.primary.light}
      >
        <Icon path={icon} size={1} color={Colors.primary.main} />
      </Box>
      <Typography variant="h5" fontWeight="bold" color="secondary">
        {tipo}
      </Typography>
      <Button variant="contained" onClick={onSelect}>
        <Typography textTransform="none">Selecionar</Typography>
      </Button>
    </Grid>
  );
};

const Cadastro = () => {
  const navigate = useNavigate();
  const selecionarCadastroPF = useCallback(() => {
    navigate('/cadastro/pf');
  }, [navigate]);

  const selecionarCadastroPJ = useCallback(() => {
    navigate('/cadastro/pj');
  }, [navigate]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" height={1} gap={8}>
      <Typography variant="h5" fontWeight="bold" color="secondary" align="center">
        Cadastrar como:
      </Typography>
      <Grid container justifyContent="center" gap={4}>
        <CardSelecao tipo="Pessoa Física" onSelect={selecionarCadastroPF} />
        <CardSelecao tipo="Pessoa Jurídica" onSelect={selecionarCadastroPJ} />
      </Grid>
    </Box>
  );
};

export default Cadastro;
