import {
  mdiCashClock,
  mdiCashMinus,
  mdiCashRefund,
  mdiCurrencyUsd,
  mdiTicketConfirmation
} from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Fab, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { forwardRef } from 'react';

import { ReactComponent as CreditCard } from '../../assets/credit-card.svg';
import { ReactComponent as DebitCard } from '../../assets/debit-card.svg';
import { ReactComponent as PicPay } from '../../assets/picpay-logo.svg';
import { ReactComponent as Pix } from '../../assets/pix-logo.svg';
import { useUserContext } from '../../contexts/User.context';
import { TipoPagamento } from '../../models/TipoPagamento';
import { TIPO_TRANSACAO, TipoTransacao, Transacao } from '../../models/Transacao.model';
import { TipoUsuario } from '../../models/Usuario';
import { Colors } from '../../shared/colors';

import CpfCnpjFormatter from './CpfCnpjFormatter';
import Currency from './Currency';

export const getIcon = (tipo: TipoTransacao, _tipoPagamento: TipoPagamento) => {
  if (tipo === TIPO_TRANSACAO.SALDO_ADICIONADO) return mdiCurrencyUsd;
  if (tipo === TIPO_TRANSACAO.COMPRA_PASSAGEM) return mdiCashMinus;
  if (tipo === TIPO_TRANSACAO.TRANSFERENCIA_ENVIADA) return mdiCashMinus;
  if (tipo === TIPO_TRANSACAO.TRANSFERENCIA_RECEBIDA) return mdiCurrencyUsd;
  if (tipo === TIPO_TRANSACAO.ESTORNO_PASSAGEM) return mdiCashRefund;
  if (tipo === TIPO_TRANSACAO.TRANSACAO_CREDITADA) return mdiCashRefund;
  if (tipo === TIPO_TRANSACAO.TRANSFERENCIA_ESTORNADA) return mdiCashRefund;
  return mdiTicketConfirmation;
};

interface IconeProps {
  tipo: TipoTransacao;
  tipoPagamento: TipoPagamento;
}

const Icone = ({ tipo, tipoPagamento }: IconeProps) => {
  switch (tipoPagamento) {
    case TipoPagamento.CartaoCredito:
      return <CreditCard />;
    case TipoPagamento.CartaoDebito:
      return <DebitCard />;
    case TipoPagamento.Pix:
      return <Pix />;
    case TipoPagamento.PicPay:
      return <PicPay />;
    case TipoPagamento.PJPosPago:
      return <Icon size={1} color={Colors.primary.main} path={mdiCashClock} />;

    default:
      return <Icon size={1} color={Colors.primary.main} path={getIcon(tipo, tipoPagamento)} />;
  }
};

interface Props {
  transacao: Transacao;
  onClick: () => void;
}

const CardTransacao = forwardRef<HTMLDivElement, Props>(({ transacao, onClick }, ref) => {
  const { userClaim } = useUserContext();
  const usuarioPF = userClaim === TipoUsuario.PESSOA_FISICA;
  const isHomePage = location.pathname === '/';

  const getCurrencyColor = (tipo: TipoTransacao) => {
    if (tipo === TIPO_TRANSACAO.COMPRA_PASSAGEM) return Colors.error.red;

    if (tipo === TIPO_TRANSACAO.TRANSFERENCIA_ENVIADA) return Colors.error.red;

    if (tipo === TIPO_TRANSACAO.TRANSFERENCIA_ESTORNADA && usuarioPF) return Colors.error.red;

    if (tipo === TIPO_TRANSACAO.TRANSFERENCIA_ESTORNADA && !isHomePage) return Colors.error.red;

    return Colors.primary.dark;
  };

  return (
    <Grid ref={ref} item xs={12}>
      <Grid
        container
        border={1}
        paddingX={2}
        marginTop={2}
        paddingY={1.5}
        borderRadius={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderColor: Colors.primary.main }}
      >
        <Grid item container xs={12}>
          <Grid item>
            <Fab
              size="small"
              sx={{
                border: 1,
                borderColor: Colors.primary.main,
                bgcolor: Colors.primary.light
              }}
            >
              <Box display="flex" alignItems="center" p={1}>
                <Icone tipo={transacao.tipo} tipoPagamento={transacao.tipoPagamento} />
              </Box>
            </Fab>
          </Grid>

          <Grid item ml={1} flex={1} container paddingX={1}>
            <Grid item xs={12} sm={6} md={5} container display="flex" flexDirection="column">
              <Box display="flex">
                <Typography variant="body1" color={Colors.gray1} lineHeight={1.5}>
                  {transacao.tipoTransacaoDescricao}
                </Typography>
              </Box>

              <Typography variant="caption" color={Colors.gray1}>
                Autorização: {transacao.numeroAutorizacao}
              </Typography>
            </Grid>
            <Grid item display="flex" flex={1} alignItems="center" gap={1} mt={{ xs: 1, sm: 0 }}>
              {transacao.origemDestinoCpfCnpj !== null && (
                <Box>
                  <Typography variant="body1" color={Colors.gray1} lineHeight={1.5}>
                    {transacao.origemDestinoNome}
                  </Typography>
                  <Typography variant="caption" color={Colors.gray1}>
                    {transacao.origemDestinoCpfCnpj.length > 11 ? 'CNPJ: ' : 'CPF: '}
                    <CpfCnpjFormatter value={transacao.origemDestinoCpfCnpj} />
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              mt={{ xs: 1, md: 0 }}
              md={transacao.origemDestinoCpfCnpj ? 4 : 6}
              justifyContent="flex-end"
            >
              {transacao.bilhetes && transacao.bilhetes.length > 0 && (
                <Grid item display="flex" alignItems="center" mr={2}>
                  <Box sx={{ cursor: 'pointer' }} onClick={onClick}>
                    <Icon path={mdiTicketConfirmation} size={1} color={Colors.primary.main} />
                  </Box>
                </Grid>
              )}
              <Grid
                item
                flexShrink={1}
                display="flex"
                flexDirection="column"
                alignItems={{ md: 'flex-end' }}
              >
                <Typography
                  fontWeight="bold"
                  color={getCurrencyColor(transacao.tipo)}
                  sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
                >
                  <Currency value={transacao.valor} />
                </Typography>

                <Typography
                  variant="caption"
                  color={Colors.gray1}
                  textAlign={{ md: 'right' }}
                  sx={{ textAlignLast: { md: 'right' } }}
                  variantMapping={{ caption: 'p' }}
                >
                  {format(new Date(transacao.dataHora), 'dd/MM/yyyy HH:mm')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

CardTransacao.displayName = 'CardTransacao';
export default CardTransacao;
