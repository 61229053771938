import { mdiFilterVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { FILTRO_USUARIO_DEFAULT, FiltroUsuario } from '../../models/FiltroUsuario.model';
import { Funcionario } from '../../models/Funcionario.model';
import FuncionarioService from '../../services/Funcionario.service';
import { Colors } from '../../shared/colors';
import useInfiniteScroll from '../../shared/hooks/infiniteScroll';
import CardAssociado from '../components/CardAssociado';
import DrawerAssociarUsuario from '../features/associar-usuario/DrawerAssociarUsuario';
import DrawerFiltrarUsuarios from '../features/filtrar-usuarios/DrawerFiltrarUsuario';
import DrawerTransferencias from '../features/realizar-transferencias/DrawerTranferencia';

const ListaUsuarios = () => {
  const [openDrawerAssociacao, setOpenDrawerAssociacao] = useState(false);
  const [openDrawerFiltroUsuario, setOpenDrawerFiltroUsuario] = useState(false);
  const [openDrawerTransferencia, setOpenDrawerTransferencia] = useState(false);
  const [filtroUsuarios, setFiltroUsuarios] = useState<FiltroUsuario>(FILTRO_USUARIO_DEFAULT);
  const [usuariosSelecionados, setUsuariosSelecionados] = useState<Funcionario[]>([]);

  const {
    refresh,
    hasNext,
    loading,
    lastElementRef,
    items: contasAssociadas
  } = useInfiniteScroll<Funcionario, FiltroUsuario>({
    load: FuncionarioService.listar,
    search: filtroUsuarios
  });

  const fecharDrawerFiltro = (filtroUsuarios: FiltroUsuario) => {
    setOpenDrawerFiltroUsuario(false);
    setFiltroUsuarios(filtroUsuarios);
  };

  const fecharDrawerAssociacao = () => {
    // setTimeout(refresh, 1000);
    refresh();
    setOpenDrawerAssociacao(false);
  };

  const fecharDrawerTransferencia = () => {
    // setTimeout(refresh, 1000);
    refresh();
    setOpenDrawerTransferencia(false);
  };

  const selecionarUsuarios = useCallback((checked: boolean, funcionario: Funcionario) => {
    if (checked)
      setUsuariosSelecionados((arr) => {
        arr.push(funcionario);
        return [...arr];
      });
    else setUsuariosSelecionados((arr) => arr.filter((f) => f.id !== funcionario.id));
  }, []);

  const carregandoItens = (loading && hasNext) || loading;

  return (
    <Grid container spacing={2} paddingY={2}>
      <Grid item xs={12} spacing={2} container alignItems="center">
        <Grid item xs={12} sm={6}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" color={Colors.secondary} display="inline" fontWeight={700}>
              Contas associadas
            </Typography>
            <Box
              display="flex"
              onClick={() => setOpenDrawerFiltroUsuario(true)}
              sx={{ cursor: 'pointer' }}
              gap={1}
            >
              <Typography fontWeight="bold" color={Colors.primary.main}>
                Filtrar
              </Typography>
              <Icon size={1} path={mdiFilterVariant} color={Colors.primary.main} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} gap={3} display={'flex'} flexDirection="row">
          <Button
            fullWidth
            variant="outlined"
            disabled={usuariosSelecionados.length < 1}
            onClick={() => setOpenDrawerTransferencia(true)}
          >
            <Typography textTransform="none">Transferência</Typography>
          </Button>

          <Button fullWidth variant="contained" onClick={() => setOpenDrawerAssociacao(true)}>
            <Typography textTransform="none" lineHeight={1}>
              Associar usuários
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {contasAssociadas.map((contaFuncionario, index, arr) => {
          const lastElement = arr.length === index + 1;
          const ref = lastElement && hasNext ? lastElementRef : null;
          return (
            <div ref={ref} key={contaFuncionario.id}>
              <CardAssociado
                onUpdate={refresh}
                onChecked={selecionarUsuarios}
                contaFuncionario={contaFuncionario}
              />
            </div>
          );
        })}
      </Grid>

      <Grid item xs={12}>
        {carregandoItens && (
          <Grid container mt={2} gap={2}>
            {Array(5)
              .fill(1)
              .map((_item, index) => index)
              .map((value) => (
                <Skeleton key={value} variant="rounded" width="100%" height={70} />
              ))}
          </Grid>
        )}
        <Typography variant="body1" fontWeight={500} color={Colors.gray1} textAlign="center">
          {!hasNext && !loading && 'Não há dados a serem exibidos'}
        </Typography>
      </Grid>

      <DrawerFiltrarUsuarios open={openDrawerFiltroUsuario} onClose={fecharDrawerFiltro} />
      <DrawerAssociarUsuario open={openDrawerAssociacao} onClose={fecharDrawerAssociacao} />
      <DrawerTransferencias
        usuarios={usuariosSelecionados}
        open={openDrawerTransferencia}
        onClose={fecharDrawerTransferencia}
      />
    </Grid>
  );
};

export default ListaUsuarios;
