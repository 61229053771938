import { isAfter, isBefore, isDate } from 'date-fns';
import * as yup from 'yup';

import { TipoTransacaoPF, TipoTransacaoPJ, TipoTransacaoPJUser } from './Transacao.model';
export interface FiltroTransacoesUsuarios {
  dataInicio?: Date | null;
  dataFinal?: Date | null;
  cpf?: string;
  tipoTransacao?: TipoTransacaoPF | TipoTransacaoPJ | TipoTransacaoPJUser | '';
  usuario?: string;
}

export interface FiltroTransacoes extends FiltroTransacoesUsuarios {
  idAutorizacao?: string;
}

export const FILTRO_TRANSACOES_USUARIOS_DEFAULT: FiltroTransacoes = {
  dataInicio: null as any,
  dataFinal: null as any,
  tipoTransacao: '',
  usuario: '',
  cpf: ''
};

export const FILTRO_TRANSACOES_DEFAULT: FiltroTransacoes = {
  ...FILTRO_TRANSACOES_USUARIOS_DEFAULT,
  idAutorizacao: ''
};

export const filtroTransacoesUsuariosFormModelSchema: yup.ObjectSchema<FiltroTransacoesUsuarios> =
  yup.object().shape(
    {
      usuario: yup.string().optional(),
      cpf: yup
        .string()
        .optional()
        .transform((val) => val.replace(/[^\d]+/g, '')),
      dataInicio: yup
        .date()
        .nullable()
        .when('dataFinal', ([dataFinal], schema) => {
          const REQUIRED = 'A data de início também deve ser preenchida!';

          if (!dataFinal) return schema.optional();
          if (!isDate(dataFinal)) return schema.required(REQUIRED);

          return schema
            .test(
              'test-endDate',
              'A data de início deve ser inferior à data final!',
              (dataInicio) => {
                return !!dataInicio && !isAfter(dataInicio, dataFinal);
              }
            )
            .required(REQUIRED);
        }),
      dataFinal: yup
        .date()
        .nullable()
        .when('dataInicio', ([dataInicio], schema) => {
          const REQUIRED = 'A data final também deve ser preenchida!';

          if (!dataInicio) return schema.optional();
          if (!isDate(dataInicio)) return schema.required(REQUIRED);
          return schema
            .test(
              'test-endDate',
              'A data final deve ser superior à data de início!',
              (dataFinal) => !!dataFinal && !isBefore(dataFinal, dataInicio)
            )
            .required(REQUIRED);
        }),
      tipoTransacao: yup.mixed<TipoTransacaoPF | ''>().optional()
    },
    [['dataInicio', 'dataFinal']]
  );

export const filtroTransacoesFormModelSchema: yup.ObjectSchema<FiltroTransacoesUsuarios> =
  filtroTransacoesUsuariosFormModelSchema.shape({
    idAutorizacao: yup.string().optional()
  });
