import { createSlice } from '@reduxjs/toolkit';

import { BASE_CONFIG } from '../../baseConfig';

const INITIAL_STATE = {
  error: '',
  session: null,
  orderRef: null,
  paymentDataStoreRes: null,
  config: {
    storePaymentMethod: true,
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true
      }
    },
    amount: {
      value: 0,
      currency: 'BRL'
    },
    locale: 'pt_BR',
    translations: {
      pt_BR: {
        'creditCard.holderName.placeholder': 'Digite o NOME como está no CARTÃO'
      }
    },
    showPayButton: true,
    clientKey: BASE_CONFIG.Adyen.ClientKey,
    environment: BASE_CONFIG.Adyen.Environment
  }
};

export const adyenPaymentSlice = createSlice({
  name: 'adyenPayment',
  initialState: INITIAL_STATE,
  reducers: {
    adyenPaymentSession: (state, action) => {
      const res = action.payload;
      state.session = res;
    },
    clearAdyenPaymentSession: (state) => {
      state.error = '';
      state.session = null;
      state.orderRef = null;
    }
  }
});

export const { adyenPaymentSession, clearAdyenPaymentSession } = adyenPaymentSlice.actions;

export default adyenPaymentSlice;
