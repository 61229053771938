import {
  CadastroPF,
  ModalEditSuccess,
  ModalError,
  ModalSuccess,
  UsuarioPF
} from '@vabembarqueja/embarque-ja-shared-components';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserContext } from 'src/contexts/User.context';
import { UsuarioService } from 'src/services';

interface Props {
  usuario?: UsuarioPF;
}

const CadastroPFController = ({ usuario }: Props) => {
  const isEditing = Boolean(usuario);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const { refreshUserInfo } = useUserContext();

  const navigateToHome = () => {
    navigate('/', { replace: true });
  };

  const handleCloseModalSuccess = () => {
    refreshUserInfo().then(() => {
      setOpen(false);
      navigateToHome();
    });
  };

  const handleCloseModalError = () => {
    setError(false);
  };

  const onValid: SubmitHandler<UsuarioPF> = (data) => {
    setLoading(true);
    if (isEditing)
      UsuarioService.alterarPF(data)
        .then(() => setOpen(true))
        .catch((err: Error) => {
          setError(true);
          setErrorMessage(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    else
      UsuarioService.cadastrarPF(data)
        .then(() => setOpen(true))
        .catch((err: Error) => {
          setError(true);
          setErrorMessage(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  return (
    <>
      <CadastroPF
        loading={loading}
        usuario={usuario}
        usuarioIncompleto={location.state}
        cadastrarUsuario={onValid}
        onClickVoltar={navigateToHome}
      />
      <ModalSuccess open={!isEditing && open} setOpen={setOpen} onClose={handleCloseModalSuccess} />
      <ModalEditSuccess
        open={isEditing && open}
        setOpen={setOpen}
        onClose={handleCloseModalSuccess}
      />
      <ModalError
        open={error}
        setOpen={setError}
        message={errorMessage}
        onClose={handleCloseModalError}
      />
    </>
  );
};

export default CadastroPFController;
