import { useState } from 'react';

import { FiltroUsuario, FILTRO_USUARIO_DEFAULT } from '../../../models/FiltroUsuario.model';
import Drawer from '../../components/Drawer';

import FormFiltrarUsuario from './FormFiltrarUsuario';

interface Props {
  open: boolean;
  onClose: (data: FiltroUsuario) => void;
}

const DrawerFiltrarUsuarios = ({ open, onClose }: Props) => {
  const [filtroUsuarios, setFiltroUsuarios] = useState<FiltroUsuario>(FILTRO_USUARIO_DEFAULT);

  const onCloseDrawer = (filtroUsuarios: FiltroUsuario) => {
    setFiltroUsuarios(filtroUsuarios);
    onClose(filtroUsuarios);
  };

  return (
    <Drawer
      title="Filtrar"
      open={open}
      onClose={() => {
        onCloseDrawer(filtroUsuarios);
      }}
    >
      <FormFiltrarUsuario
        filtroUsuarios={filtroUsuarios}
        onChangeFilter={setFiltroUsuarios}
        onClose={onCloseDrawer}
      />
    </Drawer>
  );
};

export default DrawerFiltrarUsuarios;
