import { InputLabel, TextFieldProps } from '@mui/material';
import { DatePickerProps, DatePicker as MaterialDatePicker } from '@mui/x-date-pickers';
import {
  Controller,
  ControllerProps,
  FieldError,
  FieldValues,
  get,
  useFormContext,
  useFormState
} from 'react-hook-form';

import InputError from './InputError';

interface Props<T extends FieldValues>
  extends Omit<ControllerProps<T>, 'render'>,
    Omit<DatePickerProps<Date>, 'onChange' | 'defaultValue' | 'name'>,
    Pick<TextFieldProps, 'inputProps'> {
  placeholder?: string;
  errors?: FieldError | string;
  mascara?: (texto: string) => string;
}

export default function DatePicker<T extends FieldValues>({
  name,
  control,
  errors: formErrors,
  placeholder = 'dd/mm/aaaa',
  ...inputProps
}: Props<T>) {
  const { setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const error = get(errors, name);

  return (
    <>
      <InputLabel>{inputProps.label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, name } }) => (
          <MaterialDatePicker
            value={new Date(value) ?? ''}
            onChange={(v) => {
              if (v) setValue<string>(name, v);
            }}
            slotProps={{
              textField: {
                value,
                fullWidth: true,
                sx: { marginTop: 1 },
                inputProps: { placeholder },
                variant: 'outlined',
                color: error ? 'error' : 'primary'
              }
            }}
          />
        )}
      />
      <InputError error={error ?? formErrors} />
    </>
  );
}
