import {
  AssociacaoFuncionario,
  ASSOCIACAO_FUNCIONARIO_DEFAULT
} from '../../../models/AssociacaoFuncionario.model';
import { Funcionario } from '../../../models/Funcionario.model';
import Drawer from '../../components/Drawer';

import FormDesassociarCartao from './FormDesassociarCartao';

interface Props {
  open: boolean;
  onClose: () => void;
  funcionario: Funcionario;
}

const DrawerDesassociarCartao = ({ open, funcionario, onClose }: Props) => {
  const associacaoFuncionario: AssociacaoFuncionario = {
    ...ASSOCIACAO_FUNCIONARIO_DEFAULT,
    associarCartao: true,
    cpf: funcionario.cpf,
    nome: funcionario.nome,
    email: funcionario.email,
    numeroCartao: funcionario.numeroCartaoFisico
  };

  return (
    <Drawer title="Desassociar cartão" open={open} onClose={onClose}>
      <FormDesassociarCartao onClose={onClose} associacaoFuncionario={associacaoFuncionario} />
    </Drawer>
  );
};

export default DrawerDesassociarCartao;
