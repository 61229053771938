import { yupResolver } from '@hookform/resolvers/yup';
import { mdiClose } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Box, Button, Grid, InputLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitErrorHandler, useFieldArray, useForm } from 'react-hook-form';

import { Carteira as CarteiraModel } from '../../../models/Carteira.model';
import {
  estornarTransferenciaFormModelSchema,
  FuncionarioMin,
  Transferencias
} from '../../../models/Transferencia.model';
import { CarteiraService } from '../../../services';
import FuncionarioService from '../../../services/Funcionario.service';
import { Colors } from '../../../shared/colors';
import CardCarteira from '../../components/CardCarteira';
import Currency from '../../components/Currency';
import { NumericInput } from '../../components/Form/NumericInput';
import Loading from '../../components/Loading';

interface Props {
  funcionarios: FuncionarioMin[];

  onClose: () => void;
}

const FormEstornarTranferencia = ({ funcionarios, onClose }: Props) => {
  const form = useForm<Transferencias>({
    defaultValues: { funcionarios },
    resolver: yupResolver(estornarTransferenciaFormModelSchema)
  });

  const { fields, remove } = useFieldArray({
    control: form.control,
    name: 'funcionarios'
  });

  const [loading, setLoading] = useState(true);
  const [carteiras, setCarteiras] = useState<CarteiraModel[]>([]);
  const transf = form.watch('funcionarios');

  const valorDistibuido = transf.reduce(
    (prev, cur) => prev + (isNaN(cur.valor) ? 0 : cur.valor),
    0
  );

  const onValid = (data: Transferencias) => {
    setLoading(true);

    FuncionarioService.descarregarCartao(data)
      .then(onClose)
      .finally(() => setLoading(false));
  };

  const onInvalid: SubmitErrorHandler<Transferencias> = (err) => {
    console.error(err);
  };

  useEffect(() => {
    setLoading(true);
    CarteiraService.getCarteiras()
      .then(setCarteiras)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Loading show={loading} />;

  return (
    <FormProvider {...form}>
      <Box p={3} pb={0} width={1} display="flex" flexDirection="column" alignItems="space-between">
        <Grid
          container
          top={{ xs: 60, sm: 80 }}
          zIndex={2}
          spacing={2}
          position="sticky"
          bgcolor="white"
        >
          <Grid item xs={12}>
            {carteiras.map((carteira) => {
              return <CardCarteira key={carteira.idCarteira} carteira={carteira} />;
            })}
          </Grid>
          <Grid item xs={12}>
            <Typography color={Colors.secondary} variant="h6">
              Valor estornado: <Currency value={valorDistibuido} />
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography color={Colors.secondary} variant="h6">
              Usuários
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {fields.map((data, i) => (
            <Box mb={2} key={data.id}>
              <Box mb={1} display="flex" justifyContent="space-between">
                <InputLabel>{data.nome}</InputLabel>
                <Box sx={{ cursor: 'pointer' }} onClick={() => remove(i)}>
                  <Icon path={mdiClose} size={0.8} color={Colors.gray1} />
                </Box>
              </Box>
              <NumericInput
                key={data.id}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                decimalSeparator=","
                thousandSeparator="."
                name={`funcionarios.${i}.valor`}
                value={data.valor ? data.valor : ''}
                placeholder="Informe o valor que deseja adicionar"
              />
              <Typography color={Colors.gray1} mt={0.5}>
                Saldo disponível: <Currency value={data.saldo ?? 0} />
              </Typography>
            </Box>
          ))}
        </Grid>
        <Grid
          item
          p={2}
          xs={12}
          display="flex"
          alignItems="flex-end"
          position="sticky"
          bgcolor="white"
          bottom={0}
        >
          <Button fullWidth variant="contained" onClick={form.handleSubmit(onValid, onInvalid)}>
            <Typography textTransform="none">Confirmar estorno</Typography>
          </Button>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FormEstornarTranferencia;
