import styled from 'styled-components';
import { Swiper as DefaultSwiper } from 'swiper/react';

import { Colors } from '../../../shared/colors';

export const Swiper = styled(DefaultSwiper)`
  .swiper-pagination {
    position: relative;
    bottom: 0px;
  }
  @media only screen and (max-width: 800px) {
    .swiper {
      padding: 32px;
    }

    .swiper-container {
      height: 100%;
      max-height: 100vw;

      width: 100%;
      overflow: hidden;
    }

    .swiper-slide {
      width: auto;
      flex-shrink: 0;
      display: block;
      height: 100%;
      max-height: 100%;
    }

    .swiper-wrapper {
      max-height: 100%;
      height: 100%;
      display: flex;
    }
  }

  @media only screen and (min-width: 800px) {
    .swiper {
      padding: 32px;
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    height: auto;
    background-color: 'green';
  }

  .swiper-pagination-bullet-active {
    background-color: ${Colors.secondary};
  }
`;
