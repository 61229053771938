import { Grid, Typography } from '@mui/material';

import { TipoUsuario } from 'src/models/Usuario';

import Card from '../../../assets/embarque-ja-card.svg';
import { Carteira as CarteiraModel } from '../../../models/Carteira.model';
import { Colors } from '../../../shared/colors';
import Currency from '../Currency';

import NumeroCartao from './NumeroCartao';

interface PropsCartao {
  carteira: CarteiraModel;
}

const Cartao = ({ carteira }: PropsCartao) => {
  return (
    <Grid
      sx={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        aspectRatio: '16/9',
        backgroundImage: `url(${Card})`
      }}
      container
      maxWidth={450}
      bgcolor="secondary.main"
      position="relative"
      borderRadius={1}
    >
      <Grid pr={2} item container display="flex" justifyContent="space-between" mt={2}>
        <Grid display="flex" item alignItems="center" ml={{ xs: 6.5, sm: 8 }} mt={{ xs: 3, sm: 4 }}>
          <Typography color={Colors.white} variant="body2">
            {carteira.tipoCarteira === TipoUsuario.PESSOA_FISICA
              ? 'Pessoa física'
              : 'Pessoa jurídica'}
          </Typography>
        </Grid>
        <Grid item mt={{ sm: 1 }}>
          <Typography color={Colors.white} variant="h5" fontWeight="bold">
            <Currency value={carteira.saldo} />
          </Typography>
        </Grid>
      </Grid>

      <Grid item container display="flex" mt={{ xs: 1, sm: 2 }} ml={{ xs: 2.5, sm: 3 }}>
        {Boolean(carteira.numeroCartaoVirtual) && (
          <NumeroCartao tipo="Cartão Digital" numero={carteira.numeroCartaoVirtual} />
        )}
        {Boolean(carteira.numeroCartaoFisico) && (
          <NumeroCartao tipo="Cartão Físico" numero={carteira.numeroCartaoFisico} />
        )}
      </Grid>

      <Grid
        p={1}
        minHeight={75}
        item
        container
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-end"
        mt={carteira.numeroCartaoVirtual ? 1 : { xs: 4, sm: 8 }}
      >
        <Grid item xs={5} display="flex" alignItems="flex-start">
          <Typography
            color={Colors.white}
            variant="h5"
            fontSize={{ xs: '1rem', sm: '1.2rem', lg: '1.4rem' }}
            fontWeight="bold"
          >
            {carteira.nomeUsuario}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Cartao;
