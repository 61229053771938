import { mdiClose } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Box, DrawerProps, Grid, Drawer as MuiDrawer, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import { Colors } from '../../shared/colors';

interface Props extends DrawerProps {
  title: string;
}

const Drawer = ({ title, children, ...props }: PropsWithChildren<Props>) => {
  return (
    <MuiDrawer
      anchor="right"
      sx={{ zIndex: { xs: 1250 } }}
      PaperProps={{
        sx: { width: { xs: '85%', sm: '60%', md: '45%', lg: '35%' } }
      }}
      {...props}
    >
      <Box display="flex" flexDirection="column" flex={1}>
        <Grid
          container
          px={2}
          top={0}
          zIndex={1}
          height={{ xs: 60, sm: 80 }}
          borderBottom={0.5}
          display="flex"
          bgcolor="white"
          position="sticky"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h6" color={Colors.secondary}>
              {title}
            </Typography>
          </Grid>
          <Grid item display="flex" alignItems="center">
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => props.onClose?.call(this, {}, 'backdropClick')}
            >
              <Icon path={mdiClose} size={1} color={Colors.gray1} />
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" flex={1}>
          {children}
        </Box>
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
