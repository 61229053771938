import { Box, Button, Typography } from '@mui/material';

import { Colors } from '../../shared/colors';

interface Props {
  titulo: string;
  subtitulo: string;
  textoBotao: string;
  imagemModal: string;
  onClick: () => void;
}

const FeedbackDrawer = ({ titulo, subtitulo, textoBotao, imagemModal, onClick }: Props) => {
  return (
    <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between">
      <Box display="flex" px={6} py={12} flexDirection="column" alignItems="center">
        <Typography color="secondary" variant="h6" fontWeight="bold">
          {titulo}
        </Typography>
        <Typography
          mt={2}
          px={2}
          variant="body1"
          fontSize="1.5rem"
          textAlign="center"
          color={Colors.gray1}
        >
          {subtitulo}
        </Typography>
        <Box mt={8}>
          <img src={imagemModal} alt="pagamento_realizado" />
        </Box>
      </Box>
      <Box padding={2}>
        <Button fullWidth variant="contained" onClick={onClick}>
          <Typography textTransform="none">{textoBotao}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default FeedbackDrawer;
