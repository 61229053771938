import { addDays } from 'date-fns';

import { APIPaginatedRequest } from '../models/APIPaginatedRequest';
import { APIPaginatedResponse } from '../models/APIPaginatedResponse';
import { APIResponse } from '../models/ApiResponse.model';
import { Carteira } from '../models/Carteira.model';
import { FiltroTransacoes } from '../models/FiltroTransacoes.model';
import { Transacao } from '../models/Transacao.model';

import Service from './Service';

class CarteiraService extends Service {
  static async getCarteiras(): Promise<Carteira[]> {
    return this.Http.get<APIResponse<Carteira[]>>('/carteira')
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static getTransacoesDefault(
    url = '/carteira/transacoes',
    data: APIPaginatedRequest<FiltroTransacoes>,
    signal?: AbortSignal
  ) {
    if ('dataFinal' in data.search && data.search.dataFinal)
      data.search = {
        ...data.search,
        dataFinal: addDays(data.search.dataFinal, 1)
      };

    return this.Http.post<APIResponse<APIPaginatedResponse<Transacao>>>(url, data, { signal })
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static getTransacoes(data: APIPaginatedRequest<FiltroTransacoes>, signal?: AbortSignal) {
    return CarteiraService.getTransacoesDefault('/carteira/transacoes', data, signal);
  }

  static async getTransacoesCarteiraPF(
    data: APIPaginatedRequest<FiltroTransacoes>,
    signal?: AbortSignal
  ) {
    return CarteiraService.getTransacoesDefault('/carteira/transacoes', data, signal);
  }

  static async getTransacoesCarteiraPJ(
    data: APIPaginatedRequest<FiltroTransacoes>,
    signal?: AbortSignal
  ) {
    return CarteiraService.getTransacoesDefault(
      '/carteira/transacoes/carteira-pessoa-juridica',
      data,
      signal
    );
  }
}

export default CarteiraService;
