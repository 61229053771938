import {
  UsuarioIncompleto,
  UsuarioPF,
  UsuarioPJ
} from '@vabembarqueja/embarque-ja-shared-components';
import { useEffect, useState } from 'react';

import { UsuarioService } from '../../../services';
import Loading from '../../components/Loading';
import CadastroPFController from '../cadastrar-usuario/CadastroPF/CadastroPFController';
import CadastroPJController from '../cadastrar-usuario/CadastroPJ/CadastroPJController';

type Usuario = UsuarioPF | UsuarioPJ | UsuarioIncompleto;

const EditarUsuario = () => {
  const [loading, setLoading] = useState(true);
  const [usuario, setUsuario] = useState<Usuario>();

  useEffect(() => {
    setLoading(true);
    UsuarioService.buscarConta()
      .then(setUsuario)
      .finally(() => setLoading(false));
  }, []);

  if (loading || !usuario) return <Loading fullscreen show />;
  if ('rg' in usuario) return <CadastroPFController usuario={usuario} />;
  if ('cnpj' in usuario) return <CadastroPJController usuario={usuario} />;

  return <></>;
};

export default EditarUsuario;
