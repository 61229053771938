import Drawer from '../../components/Drawer';

import FormAssociarUsuario from './FormAssociarUsuario';

interface Props {
  open: boolean;
  onClose: () => void;
}

const DrawerAssociarUsuario = ({ open, onClose }: Props) => {
  return (
    <Drawer title="Associar conta" open={open} onClose={onClose}>
      <FormAssociarUsuario onClose={onClose} />
    </Drawer>
  );
};

export default DrawerAssociarUsuario;
