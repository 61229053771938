import * as yup from 'yup';

import { validarCPF } from '../shared/functions/validarCpf';

enum StatusUsuario {
  AMBOS = 1,
  ATIVO,
  INATIVO
}
export interface FiltroUsuario {
  nome?: string;
  cpf?: string;
  numeroCartao?: string;
  status?: StatusUsuario;
}

export const FILTRO_USUARIO_DEFAULT: FiltroUsuario = {
  nome: '',
  cpf: '',
  numeroCartao: '',
  status: StatusUsuario.ATIVO
};

export const filtroUsuarioFormModelSchema: yup.ObjectSchema<FiltroUsuario> = yup
  .object<FiltroUsuario>()
  .shape({
    nome: yup.string().optional(),
    cpf: yup
      .string()
      .optional()
      .test('Teste documento', 'Documento inválido', (value) => {
        if (!value) return true;
        return validarCPF(value);
      })
      .transform((val) => val.replace(/[^\d]+/g, '')),
    numeroCartao: yup
      .string()
      .optional()
      .transform((val) => val.trim()),
    status: yup.mixed<StatusUsuario>().optional()
  });
