import { TipoPagamento } from '../../../../models/TipoPagamento';

import PagamentoPJPos from './PagamentoPJPos';
import PagamentoPorCartao from './PagamentoPorCartao';
import PagamentoPorPicPay from './PagamentoPorPicPay';
import PagamentoPorPix from './PagamentoPorPix';

interface Props {
  pagamentoId?: string;
  tipoPagamento: TipoPagamento;
  onPaymentCompleted: () => void;
  onPaymentFailed: () => void;
}

export const Pagamento = ({
  pagamentoId = '',
  tipoPagamento,
  onPaymentCompleted,
  onPaymentFailed
}: Props) => {
  switch (tipoPagamento) {
    case TipoPagamento.CartaoCredito:
    case TipoPagamento.CartaoDebito:
      return (
        <PagamentoPorCartao
          pagamentoId={pagamentoId}
          tipoPagamento={tipoPagamento}
          onPaymentFailed={onPaymentFailed}
          onPaymentCompleted={onPaymentCompleted}
        />
      );

    case TipoPagamento.Pix:
      return (
        <PagamentoPorPix
          pagamentoId={pagamentoId}
          onPaymentFailed={onPaymentFailed}
          onPaymentCompleted={onPaymentCompleted}
        />
      );

    case TipoPagamento.PicPay:
      return (
        <PagamentoPorPicPay
          pagamentoId={pagamentoId}
          onPaymentFailed={onPaymentFailed}
          onPaymentCompleted={onPaymentCompleted}
        />
      );

    case TipoPagamento.PJPosPago:
      return (
        <PagamentoPJPos onPaymentFailed={onPaymentFailed} onPaymentCompleted={onPaymentCompleted} />
      );

    default:
      return <></>;
  }
};
