import AdyenCheckout from '@adyen/adyen-web';
import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LinkPagamentoService from 'src/services/LinkPagamento.service';

import { AdyenPaymentStatus } from '../../../../models/Pagamento.model';
import { TipoPagamento } from '../../../../models/TipoPagamento';
import PagamentoService from '../../../../services/Pagamento.service';
import { Colors } from '../../../../shared/colors';
import { useAppSelector } from '../../../../shared/hooks/redux';
import { clearAdyenPaymentSession } from '../../../../shared/store/Pagamento/adyenPayment.slice';
import {
  adyenPaymentSelector,
  paymentSelector
} from '../../../../shared/store/Pagamento/payment.selectors';

import '@adyen/adyen-web/dist/adyen.css';
import './styles/pagamento.styles.css';

type Props = {
  pagamentoId: string;
  onPaymentFailed: () => void;
  onPaymentCompleted: () => void;
} & (
  | {
      type: 'pix';
      tipoCartao?: never;
    }
  | {
      type: 'card';
      tipoCartao: TipoPagamento.CartaoCredito | TipoPagamento.CartaoDebito;
    }
);

export const PagamentoAdyen = ({
  type,
  pagamentoId = '',
  onPaymentCompleted,
  onPaymentFailed,
  tipoCartao
}: Props) => {
  const dispatch = useDispatch();
  const adyenPayment = useAppSelector(adyenPaymentSelector);
  const payment = useAppSelector(paymentSelector);
  const [tipoPagamento, setTipoPagamento] = useState<TipoPagamento>();
  const navigate = useNavigate();
  const paymentContainer = useRef(null);

  useEffect(() => {
    if (type === 'card') setTipoPagamento(tipoCartao);
    else setTipoPagamento(TipoPagamento.Pix);
  }, [tipoCartao, type]);

  useEffect(() => {
    if (!tipoPagamento) return;

    const valor = payment.valor;
    const controller = new AbortController();

    if (pagamentoId) {
      LinkPagamentoService.initiateCheckout(pagamentoId)(dispatch);
    } else {
      PagamentoService.initiateCheckout(
        type,
        { tipoPagamento, valor },
        { signal: controller.signal }
      )(dispatch);
    }

    return () => controller.abort();
  }, [dispatch, payment.valor, type, tipoPagamento, pagamentoId]);

  useEffect(() => {
    const { error } = adyenPayment;
    if (error) onPaymentFailed();
  }, [adyenPayment, navigate, onPaymentFailed]);

  useEffect(() => {
    const { session, config } = adyenPayment;
    if (!session || !paymentContainer.current || !tipoPagamento) return;

    const styles = {
      base: { color: Colors.primary.dark },
      error: { color: Colors.error.dark },
      placeholder: { color: Colors.primary.main }
    };

    const createCheckout = async () => {
      const checkout = await AdyenCheckout({
        ...config,
        session,
        onPaymentCompleted: (response: any, _component: any) => {
          if (response.resultCode === AdyenPaymentStatus.Refused) onPaymentFailed();
          if (response.resultCode === AdyenPaymentStatus.Authorised) onPaymentCompleted();
        },
        onAdditionalDetails: async (state: any, _component: any) => {
          let resultCode = null;

          if (pagamentoId) resultCode = await LinkPagamentoService.getPaymentDetails(state.data);
          else resultCode = await PagamentoService.getPaymentDetails(state.data);

          if (resultCode === AdyenPaymentStatus.Refused) onPaymentFailed();
          if (resultCode === AdyenPaymentStatus.Authorised) onPaymentCompleted();
        },
        onError: () => onPaymentFailed()
      });

      let cfg = undefined;
      if (tipoPagamento === TipoPagamento.CartaoCredito)
        cfg = checkout.paymentMethodsResponse.paymentMethods.find(
          (paymentMethod) => paymentMethod.fundingSource === 'credit'
        );

      if (tipoPagamento === TipoPagamento.CartaoDebito)
        cfg = checkout.paymentMethodsResponse.paymentMethods.find(
          (paymentMethod) => paymentMethod.fundingSource === 'debit'
        );

      if (paymentContainer.current)
        checkout.create(type, { styles, ...cfg }).mount(paymentContainer.current);
    };

    createCheckout();

    return () => {
      dispatch(clearAdyenPaymentSession());
    };
  }, [
    adyenPayment,
    dispatch,
    onPaymentCompleted,
    onPaymentFailed,
    pagamentoId,
    tipoPagamento,
    type
  ]);

  return (
    <Box justifyContent="space-between" flexDirection="column" display="flex" paddingY={2} flex={1}>
      <div ref={paymentContainer}></div>
    </Box>
  );
};
