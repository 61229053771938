import { mdiCreditCardPlusOutline, mdiCreditCardRemove } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import { ModalError } from '@vabembarqueja/embarque-ja-shared-components';
import { useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import { Funcionario } from '../../models/Funcionario.model';
import FuncionarioService from '../../services/Funcionario.service';
import { Colors } from '../../shared/colors';
import DrawerAssociarCartao from '../features/associar-usuario/DrawerAssociarCartao';
import DrawerDesassociarCartao from '../features/associar-usuario/DrawerDesassociarCartao';

import Currency from './Currency';
import { Switch } from './Switch';

interface Props {
  onUpdate: () => void;
  onChecked: (checked: boolean, funcionario: Funcionario) => void;
  contaFuncionario: Funcionario;
}

const CardAssociado = ({ contaFuncionario, onChecked, onUpdate }: Props) => {
  const [checked, setChecked] = useState(false);
  const [funcionarioAtivo, setFuncionarioAtivo] = useState(contaFuncionario.ativo);
  const [openModalAssociar, setOpenModalAssociar] = useState(false);
  const [openModalDesassociar, setOpenModalDesassociar] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openTooltip, setOpenTooltip] = useState(false);
  const [loading, setLoading] = useState(false);

  const abrirModalAssociar = () => setOpenModalAssociar(true);
  const abrirModalDesassociar = () => setOpenModalDesassociar(true);

  const saldoZerado = contaFuncionario.saldoCarteira === 0;

  const fecharModalAssociar = () => {
    onUpdate();
    setOpenModalAssociar(false);
  };

  const fecharModalDesassociar = () => {
    onUpdate();
    setOpenModalDesassociar(false);
  };

  const handleCloseModalError = () => {
    setError(false);
  };
  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    if (!saldoZerado || !contaFuncionario.usuarioCadastrado) setOpenTooltip(true);
  };

  const alterarStatus = () => {
    setLoading(true);
    setChecked(false);
    const prevValue = funcionarioAtivo;
    FuncionarioService.alterarStatus({
      id: contaFuncionario.id,
      ativo: !funcionarioAtivo
    })
      .then(() => setFuncionarioAtivo(!prevValue))
      .catch((err: Error) => {
        setError(true);
        setErrorMessage(err.message);
        setFuncionarioAtivo(prevValue);
      })
      .finally(() => setLoading(false));
  };

  const cadastroPendente = !funcionarioAtivo || !contaFuncionario.usuarioCadastrado;
  const tooltipText = !contaFuncionario.usuarioCadastrado
    ? 'Essa ação não pode ser realizada para cadastro pendente'
    : 'O saldo deve estar zerado para realizar essa ação';

  useEffect(() => {
    onChecked(checked, contaFuncionario);
  }, [checked, contaFuncionario, onChecked]);
  return (
    <>
      <Grid
        container
        border={1}
        paddingX={2}
        marginBottom={2}
        paddingY={0.5}
        borderRadius={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ borderColor: Colors.primary.main }}
      >
        <Grid container item xs={12} sm={6} mb={1}>
          <Checkbox
            value={checked}
            checked={checked}
            sx={{ borderRadius: 3 }}
            onChange={(_e, checked) => setChecked(checked)}
            disabled={cadastroPendente}
          />
          <Grid item container xs={10} flexDirection="column">
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body1" fontWeight={500} color={Colors.gray1} lineHeight={1.5}>
                {contaFuncionario.nome}
              </Typography>
              {!contaFuncionario.usuarioCadastrado && (
                <Chip label="Cadastro pendente" color="warning" size="small" />
              )}
            </Box>
            <Typography variant="caption" color={Colors.gray1}>
              CPF:
              <PatternFormat
                displayType="text"
                value={contaFuncionario.cpf}
                format=" ###.###.###-##"
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={3} display="flex" alignItems="center" gap={1}>
          {contaFuncionario.numeroCartaoFisico ? (
            <Box
              p={1}
              display="flex"
              borderRadius={1}
              bgcolor={Colors.gray3}
              sx={{ cursor: 'pointer' }}
              onClick={abrirModalDesassociar}
            >
              <Icon size={1} path={mdiCreditCardRemove} color={Colors.gray1} />
            </Box>
          ) : (
            <Box
              p={1}
              display="flex"
              borderRadius={1}
              onClick={abrirModalAssociar}
              sx={{ cursor: 'pointer' }}
              bgcolor={Colors.primary.main}
            >
              <Icon size={1} color={Colors.white} path={mdiCreditCardPlusOutline} />
            </Box>
          )}
          <Box>
            <Typography color={Colors.gray1}>Cartao físico</Typography>
            <Typography color={Colors.gray1} variant="caption">
              {contaFuncionario.numeroCartaoFisico ?? 'Não associado'}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          container
          flexShrink={1}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <Typography
            fontWeight="bold"
            color={Colors.primary.dark}
            sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
          >
            <Currency value={contaFuncionario.saldoCarteira} />
          </Typography>
          <Tooltip
            title={tooltipText}
            enterTouchDelay={0}
            placement="top-start"
            open={openTooltip}
            onOpen={handleOpenTooltip}
            onClose={handleCloseTooltip}
          >
            <FormControlLabel
              label="Ativo"
              labelPlacement="start"
              disabled={!saldoZerado || !contaFuncionario.usuarioCadastrado}
              sx={{ color: Colors.gray1 }}
              control={
                loading ? (
                  <Box ml={3} mr={2} mt={2}>
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  <Switch checked={funcionarioAtivo} disabled={loading} onChange={alterarStatus} />
                )
              }
            />
          </Tooltip>
        </Grid>
      </Grid>
      <DrawerAssociarCartao
        open={openModalAssociar}
        onClose={fecharModalAssociar}
        funcionario={contaFuncionario}
      />
      <DrawerDesassociarCartao
        open={openModalDesassociar}
        onClose={fecharModalDesassociar}
        funcionario={contaFuncionario}
      />
      <ModalError
        open={error}
        setOpen={setError}
        message={errorMessage}
        onClose={handleCloseModalError}
      />
    </>
  );
};

export default CardAssociado;
