import { BilhetePassagem } from './LinkPagamento';
import { TipoPagamento } from './TipoPagamento';

export const TIPO_TRANSACAO = {
  SALDO_ADICIONADO: 1,
  COMPRA_PASSAGEM: 2,
  ESTORNO_PASSAGEM: 3,
  TRANSACAO_CREDITADA: 4,
  TRANSFERENCIA_ENVIADA: 5,
  TRANSFERENCIA_RECEBIDA: 6,
  TRANSFERENCIA_ESTORNADA: 7
} as const;

export const TIPO_TRANSACAO_PF = {
  'Saldo adicionado': TIPO_TRANSACAO.SALDO_ADICIONADO,
  'Compra de passagem': TIPO_TRANSACAO.COMPRA_PASSAGEM,
  'Estorno de passagem': TIPO_TRANSACAO.ESTORNO_PASSAGEM,
  'Transação creditada': TIPO_TRANSACAO.TRANSACAO_CREDITADA
} as const;

export const TIPO_TRANSACAO_PJ = {
  'Saldo adicionado': TIPO_TRANSACAO.SALDO_ADICIONADO,
  'Transferência enviada': TIPO_TRANSACAO.TRANSFERENCIA_ENVIADA,
  'Transferência estornada': TIPO_TRANSACAO.TRANSFERENCIA_ESTORNADA
} as const;

export const TIPO_TRANSACAO_PJ_USER = {
  'Compra de passagem': TIPO_TRANSACAO.COMPRA_PASSAGEM,
  'Estorno de passagem': TIPO_TRANSACAO.ESTORNO_PASSAGEM,
  'Transação creditada': TIPO_TRANSACAO.TRANSACAO_CREDITADA,
  'Tranferência recebida': TIPO_TRANSACAO.TRANSFERENCIA_RECEBIDA,
  'Transferência estornada': TIPO_TRANSACAO.TRANSFERENCIA_ESTORNADA
} as const;

type ObjectValues<T> = T[keyof T];

export type TipoTransacao = ObjectValues<typeof TIPO_TRANSACAO>;
export type TipoTransacaoPF = ObjectValues<typeof TIPO_TRANSACAO_PF>;
export type TipoTransacaoPJ = ObjectValues<typeof TIPO_TRANSACAO_PJ>;
export type TipoTransacaoPJUser = ObjectValues<typeof TIPO_TRANSACAO_PJ_USER>;
export interface Transacao {
  idTransacao: string;
  dataHora: string;
  numeroAutorizacao: string;
  tipo: TipoTransacao;
  valor: number;
  tipoPagamento: TipoPagamento;
  tipoPagamentoDescricao: string;
  tipoTransacaoDescricao: string;
  origemDestinoNome: string | null;
  origemDestinoCpfCnpj: string | null;
  origemDestinoCarteira: string | null;
  bilhetes?: BilhetePassagem[];
}
