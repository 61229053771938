import { PropsWithChildren } from 'react';
import { A11y, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { SwiperProps } from 'swiper/react';

import { Swiper } from './Carousel.styles';

type Props = SwiperProps;

const Carousel = ({ children, ...rest }: PropsWithChildren<Props>) => {
  return (
    <Swiper modules={[Pagination, A11y]} {...rest}>
      {children}
    </Swiper>
  );
};

export default Carousel;
