import {
  UsuarioIncompleto,
  UsuarioPF,
  UsuarioPJ
} from '@vabembarqueja/embarque-ja-shared-components';

import { APIResponse } from '../models/ApiResponse.model';
import { UsuarioPosPagoState } from '../models/Usuario';

import Service from './Service';

class UsuarioService extends Service {
  static async buscarConta() {
    return this.Http.get<APIResponse<UsuarioPF | UsuarioPJ | UsuarioIncompleto>>('/usuario/conta')
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async ativo() {
    return this.Http.get<APIResponse<{ cadastrado: boolean }>>('/usuario/ativo')
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async alterarPF(data: UsuarioPF) {
    return this.Http.put('/usuario/pessoa-fisica', data);
  }

  static async cadastrarPF(data: UsuarioPF) {
    return this.Http.post('/usuario/pessoa-fisica', data);
  }

  static async alterarPJ(data: UsuarioPJ) {
    return this.Http.put('/usuario/pessoa-juridica', data);
  }

  static async cadastrarPJ(data: UsuarioPJ) {
    return this.Http.post('/usuario/pessoa-juridica', data);
  }

  static async getTipoUsuarioPosPago() {
    return this.Http.get<APIResponse<UsuarioPosPagoState>>('/usuario/pos-pago')
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async solicitarMigracao() {
    return this.Http.put('/usuario/pessoa-juridica/migrar-pos-pago');
  }
}

export default UsuarioService;
