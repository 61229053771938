import { mdiCashClock } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';

import { PagamentoService } from '../../../../services';
import { Colors } from '../../../../shared/colors';
import { useAppSelector } from '../../../../shared/hooks/redux';
import { paymentSelector } from '../../../../shared/store/Pagamento/payment.selectors';
import Currency from '../../../components/Currency';
import Loading from '../../../components/Loading';

interface Props {
  onPaymentFailed: () => void;
  onPaymentCompleted: () => void;
}

const PagamentoPJPos = ({ onPaymentCompleted, onPaymentFailed }: Props) => {
  const [loading, setLoading] = useState(false);

  const payment = useAppSelector(paymentSelector);

  const realizarPagamento = () => {
    setLoading(true);
    PagamentoService.pagarPosPago(payment.valor)
      .then(() => onPaymentCompleted())
      .catch(() => onPaymentFailed())
      .finally(() => setLoading(false));
  };

  if (loading) return <Loading show={loading} bgColor={Colors.white} />;

  return (
    <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Box display="flex" px={{ xs: 2, md: 5 }} py={5} alignItems="center" alignSelf="center">
          <Icon path={mdiCashClock} color={Colors.primary.main} size={1.5} />
          <Typography ml={1} color="primary.main" variant="h4" fontWeight="bold">
            Pós-Pago
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          px={{ xs: 2, md: 5 }}
          alignItems="center"
          alignSelf="center"
        >
          <Typography color="secondary" variant="h5" fontWeight="bold">
            Confirmar pagamento
          </Typography>
          <Typography mt={2} textAlign="center">
            As recargas realizadas nesse mês, serão faturadas no mês seguinte!
          </Typography>
        </Box>

        <Typography mt={10} color="primary.main" variant="h3" textAlign="center" fontWeight="bold">
          <Currency value={payment.valor} />
        </Typography>
      </Box>

      <Box display="flex" alignContent="flex-end">
        <Button fullWidth variant="contained" onClick={realizarPagamento}>
          <Typography textTransform="none">Confirmar</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default PagamentoPJPos;
