import { NumericFormat } from 'react-number-format';

interface Props {
  value: number;
}

const Currency = ({ value }: Props) => {
  return (
    <NumericFormat
      prefix="R$ "
      decimalScale={2}
      fixedDecimalScale
      value={value}
      decimalSeparator=","
      thousandSeparator="."
      displayType="text"
    />
  );
};

export default Currency;
