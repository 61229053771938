import { Box, Typography } from '@mui/material';

import Currency from 'src/app/components/Currency';
import { BilhetePassagem } from 'src/models/LinkPagamento';
import { Colors } from 'src/shared/colors';

interface InformacoesBilheteProps {
  bilhete: BilhetePassagem;
}

export function InformacoesBilhete({ bilhete }: InformacoesBilheteProps): JSX.Element {
  return (
    <Box mb={3} display="flex" flexDirection="column" justifyContent="space-between">
      {bilhete.dataVenda && (
        <Box display="flex" justifyContent="space-between">
          <Typography display="inline" color={Colors.secondary}>
            Data da venda:
          </Typography>
          <Typography display="inline" fontWeight="bold" color={Colors.primary.dark}>
            {new Date(bilhete.dataVenda).toLocaleString()}
          </Typography>
        </Box>
      )}

      <Box display="flex" justifyContent="space-between">
        <Typography display="inline" color={Colors.secondary}>
          Data de embarque
        </Typography>
        <Typography display="inline" fontWeight="bold" color={Colors.primary.dark}>
          {new Date(bilhete.dataEmbarque).toLocaleDateString()}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Typography display="inline" color={Colors.secondary}>
          Linha
        </Typography>
        <Typography display="inline" fontWeight="bold" color={Colors.primary.dark}>
          {bilhete.linha}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Typography display="inline" color={Colors.secondary}>
          Origem
        </Typography>
        <Typography display="inline" fontWeight="bold" color={Colors.primary.dark}>
          {bilhete.origem}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Typography display="inline" color={Colors.secondary}>
          Destino
        </Typography>
        <Typography display="inline" fontWeight="bold" color={Colors.primary.dark}>
          {bilhete.destino}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Typography display="inline" color={Colors.secondary}>
          Classe
        </Typography>
        <Typography display="inline" fontWeight="bold" color={Colors.primary.dark}>
          {bilhete.classe}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Typography display="inline" color={Colors.secondary}>
          Poltrona
        </Typography>
        <Typography display="inline" fontWeight="bold" color={Colors.primary.dark}>
          {bilhete.poltrona}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Typography display="inline" color={Colors.secondary}>
          Valor
        </Typography>
        <Typography display="inline" fontWeight="bold" color={Colors.primary.dark}>
          <Currency value={bilhete.preco} />
        </Typography>
      </Box>

      {bilhete.pontoVenda && (
        <Box display="flex" justifyContent="space-between">
          <Typography display="inline" color={Colors.secondary}>
            Ponto de venda:
          </Typography>
          <Typography display="inline" fontWeight="bold" color={Colors.primary.dark}>
            {bilhete.pontoVenda}
          </Typography>
        </Box>
      )}

      {bilhete.operador && (
        <Box display="flex" justifyContent="space-between">
          <Typography display="inline" color={Colors.secondary}>
            Operador:
          </Typography>
          <Typography display="inline" fontWeight="bold" color={Colors.primary.dark}>
            {bilhete.operador}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
