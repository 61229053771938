import { TipoUsuario } from './Usuario';

export interface Carteira {
  idCarteira: string;
  numeroCartaoVirtual: string;
  numeroCartaoFisico: string;
  idUsuario: string;
  nomeUsuario: string;
  idPessoaJuridica: string;
  tipoCarteira: TipoUsuario;
  saldo: number;
  saldoBloqueado: boolean;
}

export enum TipoTransacaoLista {
  PESSOA_FISICA = 1,
  PESSOA_JURIDICA = 2
}
