import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import AssociarError from '../../../assets/associar-error.svg';
import AssociarFuncionario from '../../../assets/associar-funcionario.svg';
import {
  AssociacaoFuncionario,
  Cartao,
  cartaoFormModelSchema
} from '../../../models/AssociacaoFuncionario.model';
import FuncionarioService from '../../../services/Funcionario.service';
import { Colors } from '../../../shared/colors';
import FeedbackDrawer from '../../components/FeedbackDrawer';
import Loading from '../../components/Loading';

import FormCartao from './FormCartao';

interface Props {
  onClose: () => void;
  associacaoFuncionario: AssociacaoFuncionario;
}

const FormDesassociarCartao = ({ associacaoFuncionario, onClose }: Props) => {
  const [errorMSg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const form = useForm<Cartao>({
    defaultValues: associacaoFuncionario,
    resolver: yupResolver(cartaoFormModelSchema)
  });

  const desassociarCartao: SubmitHandler<Cartao> = (data) => {
    FuncionarioService.desassociarCartao(data)
      .then(() => setCompleted(true))
      .catch((err) => {
        setErrorMsg(err.message);
      })
      .finally(() => setLoading(false));
  };

  if (completed)
    return (
      <FeedbackDrawer
        titulo="Cartão desassociado!"
        subtitulo="Cartão desassociado com sucesso!"
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={AssociarFuncionario}
      />
    );

  if (errorMSg)
    return (
      <FeedbackDrawer
        titulo="Erro ao associar!"
        subtitulo={errorMSg}
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={AssociarError}
      />
    );
  return (
    <FormProvider {...form}>
      <Loading fullscreen show={loading} />
      <Box display="flex" flexDirection="column" alignItems="space-between" width={1}>
        <Box p={2}>
          <Typography color={Colors.secondary} variant="h6" mb={2}>
            Desassociar um cartão?
          </Typography>
          <FormCartao disabled />
        </Box>
        <Grid container item xs={12} p={3} alignContent="flex-end">
          <Button
            fullWidth
            variant="contained"
            onClick={form.handleSubmit(desassociarCartao, console.log)}
          >
            <Typography variant="body1" textTransform="none">
              Confirmar desassociação
            </Typography>
          </Button>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FormDesassociarCartao;
