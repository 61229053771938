import { UsuarioPF, UsuarioPJ } from '@vabembarqueja/embarque-ja-shared-components';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { useAuthenticationContext } from '../app/features/authentication/authenticationContext';
import { UserInfo } from '../app/features/authentication/authenticationState';
import TokenService from '../app/features/authentication/services/Token.service';
import { TipoUsuario, UsuarioIncompleto, UsuarioPosPagoState } from '../models/Usuario';
import { UsuarioService } from '../services';

interface Context {
  loading: boolean;
  userInfo: UserInfo | null;
  refreshUserInfo: () => Promise<void>;
  posPagoState: UsuarioPosPagoState | null;
  user: UsuarioPF | UsuarioPJ | UsuarioIncompleto | null;
}

const UserContext = createContext<Context | null>(null);

const UserProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(true);
  const { token } = useAuthenticationContext();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [posPagoState, setPosPagoState] = useState<UsuarioPosPagoState | null>(null);
  const [user, setUser] = useState<UsuarioPF | UsuarioPJ | UsuarioIncompleto | null>(null);

  const refreshUserInfo = useCallback(async () => {
    const user = await UsuarioService.buscarConta();
    if ('cnpj' in user) UsuarioService.getTipoUsuarioPosPago().then(setPosPagoState);
    setUser(user);
  }, []);

  useEffect(() => {
    if (!token) return;
    setLoading(true);

    const parseToken = TokenService.parseToken(token).then(setUserInfo);
    const buscarConta = refreshUserInfo();

    Promise.all([buscarConta, parseToken]).finally(() => setLoading(false));
  }, [refreshUserInfo, token]);

  return (
    <UserContext.Provider value={{ loading, user, userInfo, posPagoState, refreshUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};

function useUserContext() {
  const context = useContext(UserContext);
  if (context === null) throw new Error('UserContext must be rendered inside UserProvider!');

  const { loading, user, userInfo, posPagoState, refreshUserInfo } = context;
  if (loading || !userInfo) return { ...context, loading: true };

  const userPF = userInfo.extension_EmbarquejaClaim === 'UsuarioPF' && TipoUsuario.PESSOA_FISICA;
  const userPJ = userInfo.extension_EmbarquejaClaim === 'UsuarioPJ' && TipoUsuario.PESSOA_JURIDICA;
  const userClaim = userPF || userPJ || '';

  return { loading, user, userInfo, userClaim, posPagoState, refreshUserInfo };
}

export { UserProvider, useUserContext };
