import { BilhetePassagem } from 'src/models/LinkPagamento';

import Drawer from '../../components/Drawer';

import { DetalhesViagem } from './DetalhesViagem';

export interface Props {
  bilhetes: BilhetePassagem[] | null;
  onClose: () => void;
}

const DrawerDetalhesViagem = ({ bilhetes, onClose }: Props) => {
  if (bilhetes === null) return null;

  return (
    <Drawer title="Informações da passagem" open={Boolean(bilhetes)} onClose={onClose}>
      <DetalhesViagem bilhetes={bilhetes} />
    </Drawer>
  );
};

export default DrawerDetalhesViagem;
