import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { UsuarioPF } from '@vabembarqueja/embarque-ja-shared-components';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form';

import AssociarError from '../../../assets/associar-error.svg';
import AssociarFuncionario from '../../../assets/associar-funcionario.svg';
import {
  ASSOCIACAO_FUNCIONARIO_DEFAULT,
  AssociacaoFuncionario,
  associacaoFuncionarioFormModelSchema
} from '../../../models/AssociacaoFuncionario.model';
import FuncionarioService from '../../../services/Funcionario.service';
import { Colors } from '../../../shared/colors';
import FeedbackDrawer from '../../components/FeedbackDrawer';
import { Input } from '../../components/Form/Input';
import { PatternInput } from '../../components/Form/PatternInput';
import Loading from '../../components/Loading';
import { Switch } from '../../components/Switch';

import FormCartao from './FormCartao';

interface Props {
  onClose: () => void;
}

const FormAssociarUsuario = ({ onClose }: Props) => {
  const [desabilitarCampos, setDesabilitarCampos] = useState(true);
  const [errorMSg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const form = useForm<AssociacaoFuncionario>({
    defaultValues: ASSOCIACAO_FUNCIONARIO_DEFAULT,
    resolver: yupResolver(associacaoFuncionarioFormModelSchema)
  });

  const cpf = form.watch('cpf');
  const associarCartao = form.watch('associarCartao');

  const onValid = (data: AssociacaoFuncionario) => {
    setLoading(true);
    FuncionarioService.criar(data)
      .then(() => setCompleted(true))
      .catch((err) => {
        setErrorMsg(err.message);
      })
      .finally(() => setLoading(false));
  };

  const onInvalid: SubmitErrorHandler<AssociacaoFuncionario> = (err) => {
    console.log(err);
  };

  const setCamposUsuario = useCallback(
    (func: UsuarioPF | null) => {
      if (func) {
        form.setValue('email', func.email);
        form.setValue('nome', func.nome);
        setDesabilitarCampos(true);
      } else {
        form.setValue('nome', '');
        form.setValue('email', '');
        setDesabilitarCampos(false);
      }
    },
    [form]
  );

  useEffect(() => {
    form.setValue('numeroCartao', '');
    form.clearErrors(['numeroCartao']);
  }, [associarCartao, form]);

  useEffect(() => {
    if (cpf.replace(/\D/g, '').length !== 11) {
      form.clearErrors('cpf');
      return;
    }

    form.trigger('cpf').then((cpfValido) => {
      if (cpfValido) FuncionarioService.buscar(cpf).then(setCamposUsuario);
      else setDesabilitarCampos(true);
    });
  }, [form, cpf, setCamposUsuario]);

  if (completed)
    return (
      <FeedbackDrawer
        titulo="Funcionário associado!"
        subtitulo="Funcionário associado com sucesso!"
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={AssociarFuncionario}
      />
    );

  if (errorMSg)
    return (
      <FeedbackDrawer
        titulo="Erro ao associar!"
        subtitulo={errorMSg}
        textoBotao="Fechar"
        onClick={onClose}
        imagemModal={AssociarError}
      />
    );

  if (loading) return <Loading show={loading} bgColor="white" />;

  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" alignItems="space-between">
        <Grid container spacing={2} p={3}>
          <Grid item xs={12}>
            <Typography color={Colors.secondary} variant="h6">
              Dados de cadastro do usuário
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PatternInput
              label="CPF"
              name="cpf"
              control={form.control}
              placeholder="*CPF"
              format="###.###.###-##"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="nome"
              control={form.control}
              label="Nome"
              placeholder="*Nome"
              inputProps={{ maxLength: 150 }}
              disabled={desabilitarCampos}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="E-mail"
              control={form.control}
              placeholder="*E-mail"
              name="email"
              disabled={desabilitarCampos}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item container xs={12}>
            <Typography color={Colors.secondary} variant="h6">
              Deseja associar um cartão?
            </Typography>
            <Controller
              control={form.control}
              name="associarCartao"
              render={({ field: { onChange, value } }) => (
                <Switch checked={value} onChange={onChange} />
              )}
            />
          </Grid>
          <Grid item container spacing={2}>
            <FormCartao disabled={!associarCartao} />
          </Grid>
        </Grid>

        <Grid container item xs={12} p={3} alignContent="flex-end">
          <Button fullWidth variant="contained" onClick={form.handleSubmit(onValid, onInvalid)}>
            <Typography variant="body1" textTransform="none">
              Confirmar associação
            </Typography>
          </Button>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FormAssociarUsuario;
