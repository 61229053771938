import { Box, Typography } from '@mui/material';

import Currency from 'src/app/components/Currency';
import { Colors } from 'src/shared/colors';

import { Props } from './DrawerDetalhesViagem';

export const DetalhesViagem = ({ bilhetes }: Pick<Props, 'bilhetes'>) => {
  if (!bilhetes) return null;

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {bilhetes.map((bilhete) => (
        <Box key={crypto.randomUUID()} m={4} my={2}>
          <Box p={2} border={1} borderRadius={1} borderColor={Colors.gray2}>
            <Typography color={Colors.secondary} variant="h6">
              Detalhes da viagem
            </Typography>
            {bilhete.dataVenda && (
              <Box display="flex" mt={1} justifyContent="space-between">
                <Typography mr={2} color={Colors.secondary}>
                  Data da venda:
                </Typography>
                <Typography fontWeight="bold" color={Colors.primary.dark}>
                  {new Date(bilhete.dataVenda).toLocaleString()}
                </Typography>
              </Box>
            )}
            <Box display="flex" justifyContent="space-between">
              <Typography mr={2} color={Colors.secondary}>
                Data de embarque:
              </Typography>
              <Typography fontWeight="bold" color={Colors.primary.dark}>
                {new Date(bilhete.dataEmbarque).toLocaleString()}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Typography mr={2} color={Colors.secondary}>
                Linha:
              </Typography>
              <Typography fontWeight="bold" color={Colors.primary.dark}>
                {bilhete.linha}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Typography mr={2} color={Colors.secondary}>
                Origem:
              </Typography>
              <Typography fontWeight="bold" color={Colors.primary.dark}>
                {bilhete.origem}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Typography mr={2} color={Colors.secondary}>
                Destino:
              </Typography>
              <Typography fontWeight="bold" color={Colors.primary.dark}>
                {bilhete.destino}
              </Typography>
            </Box>

            <Box display="flex" mt={1} justifyContent="space-between">
              <Typography mr={2} color={Colors.secondary}>
                Classe:
              </Typography>
              <Typography fontWeight="bold" color={Colors.primary.dark}>
                {bilhete.classe}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Typography mr={2} color={Colors.secondary}>
                Poltrona:
              </Typography>
              <Typography fontWeight="bold" color={Colors.primary.dark}>
                {bilhete.poltrona}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Typography mr={2} color={Colors.secondary}>
                Preco:
              </Typography>
              <Typography fontWeight="bold" color={Colors.primary.dark}>
                <Currency value={bilhete.preco} />
              </Typography>
            </Box>

            {bilhete.pontoVenda && (
              <Box display="flex" mt={1} justifyContent="space-between">
                <Typography mr={2} color={Colors.secondary}>
                  Ponto de venda:
                </Typography>
                <Typography fontWeight="bold" color={Colors.primary.dark}>
                  {bilhete.pontoVenda}
                </Typography>
              </Box>
            )}
            {bilhete.operador && (
              <Box display="flex" mt={1} justifyContent="space-between">
                <Typography mr={2} color={Colors.secondary}>
                  Operador:
                </Typography>
                <Typography fontWeight="bold" color={Colors.primary.dark}>
                  {bilhete.operador}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
