import { mdiCashClock, mdiCreditCardOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as PicPay } from '../../../assets/picpay.svg';
import { ReactComponent as Pix } from '../../../assets/pix.svg';
import { useUserContext } from '../../../contexts/User.context';
import { TipoPagamento } from '../../../models/TipoPagamento';
import { TipoUsuario, UsuarioPosPagoState } from '../../../models/Usuario';
import { UsuarioService } from '../../../services';
import { Colors } from '../../../shared/colors';
import { useAppSelector } from '../../../shared/hooks/redux';
import { paymentSelector } from '../../../shared/store/Pagamento/payment.selectors';
import { setTipo } from '../../../shared/store/Pagamento/payment.slice';
import Currency from '../../components/Currency';
import Loading from '../../components/Loading';

interface Props {
  tipo: TipoPagamento;
}

const CardTipoPagamento = ({ tipo }: Props) => {
  const dispatch = useDispatch();

  const getLogo = () => {
    if (tipo === TipoPagamento.CartaoCredito || tipo === TipoPagamento.CartaoDebito)
      return (
        <Box display="flex" alignItems="center" justifyContent="start" flexDirection="column">
          <Icon path={mdiCreditCardOutline} color={Colors.gray1} size={1.5} />
          <Typography fontWeight="bold">
            Cartão de
            {`${tipo === TipoPagamento.CartaoCredito ? ' crédito' : ' débito'}`}
          </Typography>
        </Box>
      );

    if (tipo === TipoPagamento.PJPosPago)
      return (
        <Box display="flex" alignItems="center" justifyContent="start" flexDirection="column">
          <Icon path={mdiCashClock} color={Colors.gray1} size={1.5} />
          <Typography fontWeight="bold">Pós-Pago</Typography>
        </Box>
      );

    if (tipo === TipoPagamento.Pix) return <Pix />;
    if (tipo === TipoPagamento.PicPay) return <PicPay />;
  };

  const handleClick = useCallback(() => {
    dispatch(setTipo(tipo));
  }, [dispatch, tipo]);

  return (
    <Box
      p={2.5}
      width={164}
      height={164}
      borderRadius={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      boxShadow="0px 4px 12px rgba(7, 18, 112, 0.25)"
    >
      <Box flex={1} display="flex" alignContent="center" justifyContent="center">
        <Box alignSelf="center">{getLogo()}</Box>
      </Box>
      <Button variant="contained" onClick={handleClick}>
        <Typography textTransform="none">Selecionar</Typography>
      </Button>
    </Box>
  );
};

const SelecionarTipoPagamento = () => {
  const pagamento = useAppSelector(paymentSelector);
  const [loading, setLoading] = useState(false);
  const [solicitacaoState, setSolicitacaoState] = useState<UsuarioPosPagoState>();

  const { userClaim } = useUserContext();
  const usuarioPJ = userClaim === TipoUsuario.PESSOA_JURIDICA;

  useEffect(() => {
    if (usuarioPJ) {
      setLoading(true);
      UsuarioService.getTipoUsuarioPosPago()
        .then(setSolicitacaoState)
        .finally(() => setLoading(false));
    }
    return () => {
      setLoading(false);
    };
  }, [usuarioPJ]);

  if (loading) return <Loading show={loading} bgColor={Colors.white} />;

  return (
    <Grid item container spacing={2} px={1}>
      <Grid item xs={12}>
        <Typography color="secondary" fontWeight="bold">
          Selecione a forma de pagamento
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box
          gap={2}
          display="grid"
          justifyContent="center"
          gridTemplateColumns="repeat(auto-fit, 170px)"
        >
          <CardTipoPagamento tipo={TipoPagamento.PicPay} />
          <CardTipoPagamento tipo={TipoPagamento.Pix} />
          <CardTipoPagamento tipo={TipoPagamento.CartaoCredito} />
          <CardTipoPagamento tipo={TipoPagamento.CartaoDebito} />
          {usuarioPJ && solicitacaoState?.posPagoAtivo && (
            <CardTipoPagamento tipo={TipoPagamento.PJPosPago} />
          )}
        </Box>
      </Grid>

      <Grid item container p={2} gap={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Typography fontWeight="bold">Total</Typography>
          <Typography fontWeight="bold">
            <Currency value={pagamento.valor} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelecionarTipoPagamento;
