import { Box, Typography } from '@mui/material';

import { TipoPagamento } from '../../../../models/TipoPagamento';
import { PagamentoAdyen } from '../PagamentoAdyen/PagamentoAdyen';

interface Props {
  pagamentoId?: string;
  tipoPagamento: TipoPagamento.CartaoCredito | TipoPagamento.CartaoDebito;
  onPaymentFailed: () => void;
  onPaymentCompleted: () => void;
}

const PagamentoPorCartao = ({
  pagamentoId = '',
  tipoPagamento,
  onPaymentFailed,
  onPaymentCompleted
}: Props) => {
  return (
    <Box>
      <Typography variant="h6" color="secondary.main">
        {tipoPagamento === TipoPagamento.CartaoCredito ? 'Cartão de crédito' : 'Cartão de débito'}
      </Typography>
      <PagamentoAdyen
        type="card"
        pagamentoId={pagamentoId}
        tipoCartao={tipoPagamento}
        onPaymentFailed={onPaymentFailed}
        onPaymentCompleted={onPaymentCompleted}
      />
    </Box>
  );
};

export default PagamentoPorCartao;
