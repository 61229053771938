import { AxiosRequestConfig } from 'axios';

import { ConfirmarTokenPosPago, LinkPagamento } from 'src/models/LinkPagamento';
import { PicpayProps } from 'src/models/Picpay';

import { APIResponse } from '../models/ApiResponse.model';
import { AdyenPaymentStatus, Pagamento, ThreeDSecureDetails } from '../models/Pagamento.model';
import { adyenPaymentSession } from '../shared/store/Pagamento/adyenPayment.slice';
import { AppDispatch } from '../shared/store/store';

import Service from './Service';

class LinkPagamentoService extends Service {
  static initiateCheckout(type: string) {
    return async (dispatch: AppDispatch) => {
      const response = await this.Http.get<Pagamento>(`/linkpagamento/sessaoadyen/${type}`).then(
        this.getData
      );

      dispatch(adyenPaymentSession(response));
    };
  }

  static getPaymentDetails(threeDSecureDetails: ThreeDSecureDetails, config?: AxiosRequestConfig) {
    return this.Http.post<AdyenPaymentStatus>(
      `/linkpagamento/sessaoadyen/detail`,
      threeDSecureDetails,
      config
    ).then(this.getData);
  }

  static async buscar(id: string) {
    return this.Http.get<APIResponse<LinkPagamento>>(`/linkpagamento/consultar/${id}`)
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async confirmarTokenPosPago(data: ConfirmarTokenPosPago) {
    return this.Http.post(`/linkpagamento/autorizar`, data)
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async pagarPicpay(id: string) {
    return this.Http.get<APIResponse<PicpayProps>>(`/linkpagamento/sessaopicpay/${id}`)
      .then(this.getData)
      .then((resp) => resp.data);
  }
}

export default LinkPagamentoService;
