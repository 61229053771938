import { mdiReceiptTextMinus, mdiReceiptTextPlus } from '@mdi/js';
import { Grid } from '@mui/material';
import { useState } from 'react';

import { Funcionario } from '../../../models/Funcionario.model';
import { FuncionarioMin } from '../../../models/Transferencia.model';
import ButtonTransferencia from '../../components/ButtonTransferencia';
import Drawer from '../../components/Drawer';

import FormEnviarTranferencia from './FormEnviarTransferencia';
import FormEstornarTranferencia from './FormEstornarTransferencia';

interface Props {
  open: boolean;
  onClose: () => void;
  usuarios: Funcionario[];
}

const DrawerTransferencias = ({ usuarios, open, onClose }: Props) => {
  const funcionarios: FuncionarioMin[] = usuarios.map((value) => ({
    cpf: value.cpf,
    nome: value.nome,
    saldo: value.saldoCarteira,
    valor: 0
  }));

  const [tab, setTab] = useState(1);
  const onCloseDrawer = () => {
    setTab(1);
    onClose();
  };
  return (
    <Drawer
      title={
        tab === 1
          ? 'Transferências'
          : tab === 2
          ? 'Enviar valor'
          : tab === 3
          ? 'Estornar valor'
          : ''
      }
      open={open}
      onClose={onCloseDrawer}
    >
      {tab === 1 ? (
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12} gap={2} display="flex" flexDirection={'column'}>
            <ButtonTransferencia
              icon={mdiReceiptTextPlus}
              text="Enviar"
              onClick={() => setTab(2)}
            />
            <ButtonTransferencia
              icon={mdiReceiptTextMinus}
              text="Estornar"
              onClick={() => setTab(3)}
            />
          </Grid>
        </Grid>
      ) : tab === 2 ? (
        <FormEnviarTranferencia onClose={onCloseDrawer} funcionarios={funcionarios} />
      ) : tab === 3 ? (
        <FormEstornarTranferencia onClose={onCloseDrawer} funcionarios={funcionarios} />
      ) : null}
    </Drawer>
  );
};

export default DrawerTransferencias;
