import { mdiAccount, mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Drawer, Popover, Typography } from '@mui/material';
import MaterialAppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Logo from '../../../assets/logo.png';
import { useUserContext } from '../../../contexts/User.context';
import { TipoUsuario } from '../../../models/Usuario';
import { Routes } from '../../../routes/index.routes';
import { useAuthenticationContext } from '../../features/authentication/authenticationContext';
import Link from '../Link/Link';

function AppBar() {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuthenticationContext();
  const { userInfo, userClaim } = useUserContext();

  const openPopover = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const usuarioPJ = userClaim === TipoUsuario.PESSOA_JURIDICA;

  const irParaHome = () => navigate(Routes.Home);
  const irParaConta = () => navigate(Routes.Conta.Principal);
  const sair = useCallback(() => logout(), [logout]);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <>
      <MaterialAppBar position="static" sx={{ zIndex: { xs: 1201 } }}>
        <Toolbar disableGutters sx={{ height: { xs: 60, sm: 80 } }}>
          <Container maxWidth="lg">
            <Box flex={1} display="flex" alignItems="center" justifyContent="space-between">
              {usuarioPJ && (
                <Box
                  gap={2}
                  display={{ xs: 'block', sm: 'none' }}
                  onClick={() => setOpen((prev) => !prev)}
                >
                  <Icon path={mdiMenu} size={1.5} />
                </Box>
              )}

              <Box onClick={irParaHome}>
                <img src={Logo} alt="logo" />
              </Box>

              {userInfo && (
                <>
                  <Box gap={2} display={{ xs: 'none', sm: 'flex' }} alignItems="center">
                    <Box display="flex" flexDirection="column">
                      <Typography fontWeight="bold">{userInfo.nome}</Typography>
                      <Box sx={{ cursor: 'pointer' }} onClick={irParaConta}>
                        <Typography color="secondary" fontSize="0.9rem">
                          Minha conta
                        </Typography>
                      </Box>
                    </Box>

                    <Box onClick={sair} sx={{ cursor: 'pointer' }} ml={2}>
                      Sair
                    </Box>
                  </Box>

                  <Box
                    gap={2}
                    onClick={handleClick}
                    sx={{ cursor: 'pointer' }}
                    display={{ xs: 'block', sm: 'none' }}
                  >
                    <Icon path={mdiAccount} size={1.5} />
                  </Box>
                  <Popover
                    id={id}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                  >
                    <Box sx={{ cursor: 'pointer' }} onClick={irParaConta}>
                      <Typography sx={{ p: 1 }}>Minha conta</Typography>
                    </Box>
                    <Box onClick={sair} sx={{ cursor: 'pointer' }}>
                      <Typography sx={{ p: 1 }}>Sair</Typography>
                    </Box>
                  </Popover>
                </>
              )}
            </Box>
          </Container>
        </Toolbar>

        {usuarioPJ && (
          <Toolbar
            disableGutters
            sx={{
              minHeight: { xs: 56 },
              alignItems: 'flex-end',
              bgcolor: 'primary.dark',
              display: { xs: 'none', sm: 'flex' }
            }}
          >
            <Box gap={3} flex={1} display="flex" alignItems="flex-end" justifyContent="center">
              <Link to={Routes.Home}>Carteira</Link>
              <Link to={Routes.Usuarios.Principal}>Gestão de usuários</Link>
              <Link to={Routes.Transacoes.Principal}>Transações de usuários</Link>
              <Link to={Routes.Extrato.Principal}>Consumo pós-pago</Link>
            </Box>
          </Toolbar>
        )}
      </MaterialAppBar>

      <Drawer
        anchor="left"
        open={open && usuarioPJ}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { width: '75%', pt: '60px' } }}
      >
        <Box p={2} gap={3} display="flex" flexDirection="column" justifyContent="center">
          <Link to={Routes.Home}>Carteira</Link>
          <Link to={Routes.Usuarios.Principal}>Gestão de usuários</Link>
          <Link to={Routes.Transacoes.Principal}>Transações de usuários</Link>
          <Link to={Routes.Extrato.Principal}>Consumo pós-pago</Link>
        </Box>
      </Drawer>
    </>
  );
}
export default AppBar;
