import { mdiCalendar, mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Fab,
  Grid,
  Skeleton,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';

import { DetalheExtrato, Extrato } from '../../models/Extrato';
import { PagamentoService } from '../../services';
import { Colors } from '../../shared/colors';

import Currency from './Currency';

type Props = Extrato;

const CardExtrato = ({ mesAno, valorPeriodo }: Props) => {
  const [mes, ano] = mesAno.split('-');
  const [detalhe, setDetalhe] = useState<DetalheExtrato[]>();

  const getDetalhes = () => {
    const periodo = { mes: Number(mes), ano: Number(ano) };
    PagamentoService.getExtraoDetalhado(periodo).then(setDetalhe);
  };

  return (
    <Box pb={2}>
      <Accordion sx={{ width: '100%', border: `1px solid ${Colors.primary.main}` }}>
        <AccordionSummary
          onClick={getDetalhes}
          expandIcon={<Icon size={1.3} color={Colors.primary.main} path={mdiChevronDown} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid
            mr={1}
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ borderColor: Colors.primary.main }}
          >
            <Grid item xs={8} display="flex" alignItems="center" justifyContent="flex-start">
              <Box mr={2}>
                <Fab
                  size="small"
                  sx={{
                    border: 1,
                    borderColor: Colors.primary.main,
                    bgcolor: Colors.primary.light
                  }}
                >
                  <Icon size={0.75} color={Colors.primary.main} path={mdiCalendar} />
                </Fab>
              </Box>
              <Box>
                <Typography variant="body1" color={Colors.gray1} lineHeight={1.5}>
                  Mês/ano
                </Typography>
                <Typography
                  fontWeight="bold"
                  color={Colors.primary.main}
                  sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
                >
                  {mes} / {ano}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={4}
              container
              flexShrink={1}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              <Typography
                variant="caption"
                color={Colors.gray1}
                textAlign="right"
                sx={{ textAlignLast: 'right' }}
                variantMapping={{ caption: 'p' }}
              >
                Total
              </Typography>
              <Box>
                <Typography
                  fontWeight="bold"
                  color={Colors.primary.main}
                  sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}
                >
                  <Currency value={valorPeriodo}></Currency>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {detalhe ? (
            detalhe.map((d) => (
              <Grid key={new Date(d.data).getTime()} container gap={1} justifyContent="center">
                <Grid item xs={12} mt={1}>
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={10}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="body1" fontWeight={600} color={Colors.gray1}>
                      Saldo Adicionado
                    </Typography>
                    <Typography variant="body2" color={Colors.gray1}>
                      {format(new Date(d.data), 'dd/MM/yyyy HH:mm')}
                    </Typography>
                  </Box>
                  <Typography fontWeight="bold" color={Colors.gray1}>
                    <Currency value={d.valor}></Currency>
                  </Typography>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid container gap={2}>
              <Grid item xs={12} mt={1}>
                <Divider />
              </Grid>
              <Grid
                item
                container
                xs={12}
                px={9}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={2} display="flex" flexDirection="column" gap={1}>
                  <Skeleton variant="rounded" width="100%" height={18} />
                  <Skeleton variant="rounded" width="90%" height={10} />
                </Grid>
                <Grid item xs={1} display="flex" justifyContent="center">
                  <Skeleton variant="rounded" width="100%" height={15} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CardExtrato;
