import { Box, Typography } from '@mui/material';

import { Colors } from '../../../shared/colors';

export interface ErrorCarteiraProps {
  message?: string;
}

export default function ErrorCarteira(props: ErrorCarteiraProps) {
  return (
    <Box
      width={1}
      sx={{ backgroundColor: Colors.error.red }}
      color={Colors.white}
      height="fit-content"
      borderRadius={'0 0 0.5rem 0.5rem'}
    >
      <Typography textAlign="center">{props.message}</Typography>
    </Box>
  );
}
