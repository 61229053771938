import { Grid, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import { Colors } from '../../../shared/colors';

interface Props {
  saldo: number | '';
  adicionarSaldo: (valor: number | '') => void;
}

const AdicionarSaldo = ({ saldo, adicionarSaldo: setSaldo }: Props) => {
  const [saldoBotao, setSaldoBotao] = useState<number | ''>(saldo);

  const handleChange = useCallback(
    (_event: React.MouseEvent<HTMLElement>, saldo: number) => {
      setSaldoBotao(saldo);
      setSaldo(saldo);
    },
    [setSaldo]
  );

  const onValueChange = useCallback(
    (values: NumberFormatValues) => {
      const { floatValue } = values;
      setSaldo(floatValue ?? 0);
      setSaldoBotao('');
    },
    [setSaldo]
  );

  const onInputFocus = useCallback(() => {
    setSaldo('');
    setSaldoBotao('');
  }, [setSaldo]);

  return (
    <Grid item container display="flex">
      <Grid item xs={12}>
        <Typography color="secondary" fontWeight="bold" pb={2}>
          Valores para adicionar (R$)
        </Typography>
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="center">
        <ToggleButtonGroup exclusive color="secondary" value={saldoBotao} onChange={handleChange}>
          <ToggleButton sx={{ color: Colors.primary.main }} value={50}>
            <Typography fontWeight={600}>R$ 50</Typography>
          </ToggleButton>
          <ToggleButton sx={{ color: Colors.primary.main }} value={100}>
            <Typography fontWeight={600}>R$ 100</Typography>
          </ToggleButton>
          <ToggleButton sx={{ color: Colors.primary.main }} value={150}>
            <Typography fontWeight={600}>R$ 150</Typography>
          </ToggleButton>
          <ToggleButton sx={{ color: Colors.primary.main }} value={200}>
            <Typography fontWeight={600}>R$ 200</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography color="primary" py={1}>
          Ou informe outro valor:
        </Typography>
        <NumericFormat
          fullWidth
          prefix="R$ "
          color="primary"
          decimalScale={2}
          fixedDecimalScale
          value={saldo ? saldo : ''}
          decimalSeparator=","
          thousandSeparator="."
          customInput={TextField}
          onFocus={onInputFocus}
          onValueChange={onValueChange}
          placeholder="Informe o valor que deseja adicionar"
        />
      </Grid>
    </Grid>
  );
};

export default AdicionarSaldo;
