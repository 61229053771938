import { Box, Button, Grid, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { Extrato } from '../../../models/Extrato';
import { SolicitacaoState } from '../../../models/Usuario';
import { PagamentoService, UsuarioService } from '../../../services';
import { Colors } from '../../../shared/colors';
import CardExtrato from '../../components/CardExtrato';
import Loading from '../../components/Loading';

interface Props {
  statusSolicitacao: SolicitacaoState;
  posPagoAtivo: boolean;
}

const Consumo = () => {
  const [items, setItems] = useState<Extrato[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    PagamentoService.getTransacoesPosPago()
      .then(setItems)
      .finally(() => setLoading(false));
  }, []);

  const sorted = items
    .map((item) => {
      const [mes, ano] = item.mesAno.split('-');
      return { ...item, mes: Number(mes), ano: Number(ano) };
    })
    .sort((a, b) => b.ano - a.ano || b.mes - a.mes);

  return (
    <>
      {loading && (
        <Grid container gap={2} py={2} px={2}>
          {Array(5)
            .fill(1)
            .map((_item, index) => index)
            .map((value) => (
              <Skeleton key={value} variant="rounded" width="100%" height={70} />
            ))}
        </Grid>
      )}

      <Grid item xs={12}>
        {sorted.map((transacoes) => {
          return (
            <div key={transacoes.mesAno}>
              <CardExtrato mesAno={transacoes.mesAno} valorPeriodo={transacoes.valorPeriodo} />
            </div>
          );
        })}

        {sorted.length === 0 && !loading && (
          <Typography variant="h6" color={Colors.primary.main}>
            Não existem transações referentes a este período para serem exibidas.
          </Typography>
        )}
      </Grid>
    </>
  );
};

const ExibirConsumoPosPago = ({ statusSolicitacao, posPagoAtivo }: Props) => {
  const [aprovacaoPendente, setAprovacaoPendente] = useState(false);
  const [loading, setLoading] = useState(false);

  const solicitarMigracao = () => {
    setLoading(true);
    UsuarioService.solicitarMigracao()
      .then(() => setAprovacaoPendente(true))
      .finally(() => setLoading(false));
  };
  const posPagoRejeitado =
    SolicitacaoState.AVALIADO === statusSolicitacao && posPagoAtivo === false;

  if (statusSolicitacao === SolicitacaoState.AVALIADO && posPagoAtivo === true) {
    return <Consumo />;
  }

  const posPagoNaoSolicitado =
    statusSolicitacao === SolicitacaoState.NAO_SOLICITADO && !aprovacaoPendente;

  const getTitle = () => {
    if (posPagoNaoSolicitado) return 'Torne-se um cliente pós pago.';
    if (posPagoRejeitado) return 'Solicitação rejeitada';
    return 'Solicitação em análise!';
  };

  const getDescription = () => {
    if (posPagoNaoSolicitado)
      return 'Ao tornar-se um cliente PJ pós pago, você poderá efetuar recargas que serão faturadas no mês subsequente ao mês do consumo.';
    if (posPagoRejeitado)
      return 'Solicitação de migração para fazer parte do plano de consumo pós pago foi rejeitada.';
    return 'Solicitação de migração para fazer parte do plano de consumo pós pago em análise. Assim que avaliarmos sua solicitação, informaremos o status dela por aqui.';
  };

  return (
    <Grid item xs={12}>
      <Box
        p={5}
        display="flex"
        borderRadius={2}
        flexDirection="column"
        boxShadow=" 0px 5px 20px rgba(0, 0, 0, 0.15)"
      >
        <Typography variant="h6" color={Colors.gray1}>
          {getTitle()}
        </Typography>

        <Typography color={Colors.gray1} my={3} textAlign="justify">
          {getDescription()}
        </Typography>

        {posPagoNaoSolicitado && (
          <Box alignSelf="end">
            <Button variant="contained" onClick={solicitarMigracao}>
              <Typography variant="body1" textTransform="none" lineHeight={1}>
                Solicitar avaliação
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
      <Loading bgColor="transparent" show={loading} />
    </Grid>
  );
};

export default ExibirConsumoPosPago;
