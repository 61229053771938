import { Button, InputLabel, TextField, Typography } from '@mui/material';
import { useRef, useState } from 'react';

import InputError from 'src/app/components/Form/InputError';
import { ConfirmarTokenPosPago } from 'src/models/LinkPagamento';
import LinkPagamentoService from 'src/services/LinkPagamento.service';

export interface ConfirmacaoPosPagoProps {
  idSolicitacaoLink: string;
  onError: (error: string) => void;
  onSucess: () => void;
}

export function ConfirmacaoPosPago({
  onError,
  onSucess,
  idSolicitacaoLink
}: ConfirmacaoPosPagoProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [errorMsg, setError] = useState('');

  const handleConfirm = () => {
    if (!ref.current?.value || ref.current?.value.length < 5) {
      setError('Token inválido');
      return;
    }

    setError('');

    const confirmacaoData: ConfirmarTokenPosPago = {
      idSolicitacaoLink,
      token: ref.current.value.toUpperCase()
    };

    LinkPagamentoService.confirmarTokenPosPago(confirmacaoData)
      .then(onSucess)
      .catch((err) => onError(err.message));
  };

  return (
    <>
      <InputLabel>Token de confirmação</InputLabel>
      <TextField
        inputRef={ref}
        fullWidth
        sx={{ marginTop: 1 }}
        placeholder="Token de confirmação para carga pós pago"
        color={errorMsg ? 'error' : 'primary'}
      />
      <InputError error={errorMsg}></InputError>
      <Button sx={{ marginTop: 1 }} fullWidth variant="contained" onClick={handleConfirm}>
        <Typography textTransform="none">Confirmar</Typography>
      </Button>
    </>
  );
}
