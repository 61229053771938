import { Box, Grid, Skeleton } from '@mui/material';
import { SwiperSlide } from 'swiper/react';

import { Carteira as CarteiraModel } from '../../models/Carteira.model';
import Carousel from '../components/Carousel/Carousel';
import Carteira from '../components/Carteira/Carteira';

interface Props {
  carteiras: CarteiraModel[];
}

const ListarCarteiras = ({ carteiras }: Props) => {
  const getCarouselCarteiras = () => {
    return (
      <Box sx={{ maxWidth: { xs: '90vw', lg: '70vw' } }}>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Carousel
            navigation
            spaceBetween={50}
            pagination={{ clickable: true }}
            breakpoints={{
              768: { slidesPerView: 2 }
            }}
          >
            {carteiras.map((carteira) => (
              <SwiperSlide key={carteira.idCarteira}>
                <Carteira carteira={carteira} />
              </SwiperSlide>
            ))}
          </Carousel>
        </Box>

        <Box gap={2} justifyContent="start" sx={{ display: { xs: 'none', lg: 'flex' } }}>
          {carteiras.map((carteira) => (
            <Carteira key={carteira.idCarteira} carteira={carteira} />
          ))}
        </Box>
      </Box>
    );
  };

  const getCarteiraUnica = () => {
    const [carteira] = carteiras;

    if (!carteira)
      return (
        <Box maxWidth={450} width={{ xs: '100%' }} height={{ xs: 200, sm: 260 }}>
          <Skeleton variant="rounded" height="100%" />
        </Box>
      );
    return <Carteira key={carteira.idCarteira} carteira={carteira} />;
  };

  return (
    <Grid item xs={12} display="flex" justifyContent="start">
      {carteiras.length > 1 ? getCarouselCarteiras() : getCarteiraUnica()}
    </Grid>
  );
};

export default ListarCarteiras;
