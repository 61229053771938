import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { PatternInput } from '../../components/Form/PatternInput';

interface Props {
  disabled?: boolean;
}

const FormCartao = ({ disabled = false }: Props) => {
  const { control } = useFormContext();

  return (
    <Grid item container spacing={2} alignContent="flex-start">
      <Grid item xs={12}>
        <PatternInput
          fullWidth
          label="Número do cartão"
          name="numeroCartao"
          control={control}
          placeholder="*Número do cartão"
          disabled={disabled}
          format="############"
        />
      </Grid>
    </Grid>
  );
};

export default FormCartao;
