import { AxiosRequestConfig } from 'axios';

import { APIResponse } from '../models/ApiResponse.model';
import { DetalheExtrato, Extrato } from '../models/Extrato';
import { AdyenPaymentStatus, Pagamento, ThreeDSecureDetails } from '../models/Pagamento.model';
import { PicpayProps } from '../models/Picpay';
import { adyenPaymentSession } from '../shared/store/Pagamento/adyenPayment.slice';
import { AppDispatch } from '../shared/store/store';

import Service from './Service';

class PagamentoService extends Service {
  static initiateCheckout(type: string, pagamento: Pagamento, config?: AxiosRequestConfig) {
    return async (dispatch: AppDispatch) => {
      const response = this.Http.post<Pagamento>(`/sessions?type=${type}`, pagamento, config).then(
        this.getData
      );

      dispatch(adyenPaymentSession(await response));
    };
  }

  static getPaymentDetails(threeDSecureDetails: ThreeDSecureDetails, config?: AxiosRequestConfig) {
    return this.Http.post<AdyenPaymentStatus>(`/payment/detail`, threeDSecureDetails, config).then(
      this.getData
    );
  }

  static async pagarPicpay(valor: number) {
    return this.Http.post<APIResponse<PicpayProps>>('/pagamento/picpay', {
      valor
    })
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async pagarPosPago(valor: number) {
    return this.Http.post<APIResponse<PicpayProps>>('/pagamento/pos-pago', {
      valor
    })
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async getTransacoesPosPago(signal?: AbortSignal) {
    return this.Http.get<APIResponse<Extrato[]>>(`pagamento/pessoa-juridica/extrato/pos-pago`, {
      signal
    })
      .then(this.getData)
      .then((resp) => resp.data);
  }

  static async getExtraoDetalhado(data: { mes: number; ano: number }, signal?: AbortSignal) {
    return this.Http.post<APIResponse<DetalheExtrato[]>>(
      `pagamento/pessoa-juridica/extrato/pos-pago/detalhe`,
      data,
      { signal }
    )
      .then(this.getData)
      .then((resp) => resp.data);
  }
}

export default PagamentoService;
