import * as yup from 'yup';

export interface FuncionarioMin {
  cpf: string;
  valor: number;
  nome?: string;
  saldo?: number;
}
export interface Transferencias {
  funcionarios: FuncionarioMin[];
}

export const TRANSFERENCIA_DEFAULT: Transferencias = {
  funcionarios: []
};

const MSG_REQUIRED = 'Campo obrigatório';
const MIN_ZERO = 'Valor deve ser maior que 0';
const MIN_SALDO = 'Valor deve ser menor que o saldo disponível do usuário';

export const enviarTransferenciaFormModelSchema: yup.ObjectSchema<Transferencias> = yup
  .object()
  .shape({
    funcionarios: yup
      .array()
      .of(
        yup.object<FuncionarioMin>().shape({
          cpf: yup.string().required(MSG_REQUIRED),
          valor: yup
            .number()
            .test('saldo', MIN_ZERO, (val) => val !== undefined && val > 0)
            .required(MSG_REQUIRED),
          nome: yup.string().optional(),
          saldo: yup.number().optional()
        })
      )
      .required(MSG_REQUIRED)
  });

export const estornarTransferenciaFormModelSchema: yup.ObjectSchema<Transferencias> = yup
  .object()
  .shape({
    funcionarios: yup
      .array()
      .of(
        yup.object().shape({
          cpf: yup.string().required(MSG_REQUIRED),
          valor: yup
            .number()
            .when('saldo', ([saldo], schema) => schema.max(saldo, MIN_SALDO))
            .test('valor', MIN_ZERO, (val) => val !== undefined && val > 0)
            .required(MSG_REQUIRED),
          nome: yup.string().optional(),
          saldo: yup.number().optional()
        })
      )
      .required(MSG_REQUIRED)
  });
