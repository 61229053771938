import { Box, Button, Typography } from '@mui/material';

import ImagemErro from '../../../assets/erro-pagamento.svg';
import { Colors } from '../../../shared/colors';

interface Props {
  onPaymentFailed: () => void;
}

const PagamentoRecusado = ({ onPaymentFailed }: Props) => {
  return (
    <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between">
      <Box
        display="flex"
        px={{ xs: 2, md: 5 }}
        py={{ xs: 5, md: 10 }}
        flexDirection="column"
        alignItems="center"
      >
        <Typography color="secondary" variant="h6" fontWeight="bold">
          Algo deu errado!
        </Typography>
        <Typography
          mt={2}
          variant="body1"
          fontSize={{ sm: '1.5rem' }}
          textAlign="center"
          color={Colors.gray1}
        >
          Sua transação não foi finalizada! Para tentar novamente clique no botão abaixo.
        </Typography>
      </Box>
      <Box display="flex" flex={1} px={{ xs: 2, md: 5 }} py={{ xs: 5, md: 10 }}>
        <img src={ImagemErro} alt="pagamento_realizado" />
      </Box>
      <Box display="flex" alignContent="flex-end">
        <Button fullWidth variant="contained" onClick={onPaymentFailed}>
          <Typography textTransform="none">Voltar à tela de pagamento</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default PagamentoRecusado;
