import { Box, Grid, Typography } from '@mui/material';
import { useLayoutEffect } from 'react';

import { useUserContext } from '../../contexts/User.context';
import { Colors } from '../../shared/colors';
import ExibirConsumoPosPago from '../features/exibir-consumo-pos-pago/ExibirConsumoPosPago';

const Extrato = () => {
  const { posPagoState, refreshUserInfo } = useUserContext();

  useLayoutEffect(() => {
    refreshUserInfo();
  }, [refreshUserInfo]);

  return (
    <Grid container spacing={2} paddingY={2}>
      <Grid item xs={12}>
        <Box height={40} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" color={Colors.secondary} fontWeight={700}>
            Consumo pós-pago
          </Typography>
        </Box>
      </Grid>

      {posPagoState && (
        <ExibirConsumoPosPago
          posPagoAtivo={posPagoState.posPagoAtivo}
          statusSolicitacao={posPagoState.solicitacao}
        />
      )}
    </Grid>
  );
};

export default Extrato;
