import { mdiCreditCardOutline, mdiCreditCardWirelessOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Grid, Typography } from '@mui/material';

import { Colors } from '../../../shared/colors';
interface PropsCartao {
  numero: string;
  tipo: 'Cartão Físico' | 'Cartão Digital';
}
export default function NumeroCartao({ numero, tipo }: PropsCartao) {
  const xs = tipo === 'Cartão Físico' ? 5 : 6;
  const icone = tipo === 'Cartão Físico' ? mdiCreditCardOutline : mdiCreditCardWirelessOutline;

  return (
    <Grid item xs={xs}>
      <Grid container>
        <Grid item xs={12}>
          <Typography color={Colors.white} variant="body2">
            {tipo}
          </Typography>
        </Grid>
        <Grid item container display="flex" alignItems="center">
          <Icon color={Colors.primary.main} path={icone} size={1} />
          <Typography ml={1} color={Colors.white} variant="body2">
            {numero}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
