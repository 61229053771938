import { useState } from 'react';

import { FiltroTransacoes } from '../../../models/FiltroTransacoes.model';
import {
  TIPO_TRANSACAO_PF,
  TIPO_TRANSACAO_PJ,
  TIPO_TRANSACAO_PJ_USER
} from '../../../models/Transacao.model';
import Drawer from '../../components/Drawer';

import FiltrarTransacoes from './FiltrarTransacoes';

type TipoTransacao =
  | typeof TIPO_TRANSACAO_PF
  | typeof TIPO_TRANSACAO_PJ
  | typeof TIPO_TRANSACAO_PJ_USER;
interface Props {
  open: boolean;
  onClose: (data: FiltroTransacoes) => void;
  permitirFiltrarUsuarios?: boolean;
  tipoTransacoes: TipoTransacao;
}

const DrawerFiltrarTransacao = ({
  open,
  onClose,
  tipoTransacoes,
  permitirFiltrarUsuarios = false
}: Props) => {
  const [filtroTransacao, setFiltroTransacao] = useState<FiltroTransacoes>({});

  const onCloseDrawer = (filtroTransacao: FiltroTransacoes) => {
    setFiltroTransacao(filtroTransacao);
    onClose(filtroTransacao);
  };

  const tiposTransacaoSelect = Object.entries(tipoTransacoes).map(([key, value]) => ({
    label: key,
    value
  }));

  return (
    <Drawer
      title="Filtrar"
      open={open}
      onClose={() => {
        onCloseDrawer(filtroTransacao);
      }}
    >
      <FiltrarTransacoes
        tiposTransacao={tiposTransacaoSelect}
        filtroTransacoes={filtroTransacao}
        onChangeFilter={setFiltroTransacao}
        onClose={onCloseDrawer}
        permitirFiltrarUsuarios={permitirFiltrarUsuarios}
      />
    </Drawer>
  );
};

export default DrawerFiltrarTransacao;
