import { mdiFilterVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';

import { BilhetePassagem } from 'src/models/LinkPagamento';

import { FiltroTransacoes } from '../../models/FiltroTransacoes.model';
import { FiltroUsuario } from '../../models/FiltroUsuario.model';
import { TIPO_TRANSACAO_PJ_USER, Transacao } from '../../models/Transacao.model';
import FuncionarioService from '../../services/Funcionario.service';
import { Colors } from '../../shared/colors';
import useInfiniteScroll from '../../shared/hooks/infiniteScroll';
import CardTransacao from '../components/CardTransacao';
import DrawerDetalhesViagem from '../features/detalhes-viagem/DrawerDetalhesViagem';
import DrawerFiltrarTransacao from '../features/filtrar-transacoes/DrawerFiltrarTransacaoUsuarios';

const TransacoesUsuarios = () => {
  const [filtroTransacoes, setFiltroTransacoes] = useState<FiltroUsuario>();

  const [openDrawerFiltroTransacao, setOpenDrawerFiltroTransacao] = useState(false);
  const [bilhetesPassagem, setBilhetespassagem] = useState<BilhetePassagem[] | null>(null);

  const closeDrawerFiltro = (filtroTransacoes: FiltroTransacoes) => {
    setFiltroTransacoes(filtroTransacoes);
    setOpenDrawerFiltroTransacao(false);
  };

  const {
    loading,
    hasNext,
    lastElementRef,
    items: transacoes
  } = useInfiniteScroll<Transacao, FiltroUsuario>({
    load: FuncionarioService.listarTransacoes,
    search: filtroTransacoes
  });

  const carregandoItens = (loading && hasNext) || loading;

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" color={Colors.secondary} display="inline" fontWeight={700}>
            Transações de usuários
          </Typography>
          <Box
            gap={1}
            height={40}
            display="flex"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpenDrawerFiltroTransacao(true)}
          >
            <Typography fontWeight="bold" color={Colors.primary.main}>
              Filtrar
            </Typography>
            <Icon size={1} path={mdiFilterVariant} color={Colors.primary.main} />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {transacoes.map((transacao, index, arr) => {
          const lastElement = arr.length === index + 1;
          const ref = lastElement && hasNext ? lastElementRef : null;
          return (
            <CardTransacao
              ref={ref}
              key={transacao.idTransacao}
              onClick={() => setBilhetespassagem(transacao.bilhetes ?? null)}
              transacao={transacao}
            />
          );
        })}
      </Grid>

      <Grid item xs={12}>
        {carregandoItens && (
          <Grid container mt={2} gap={2}>
            {Array(5)
              .fill(1)
              .map((_item, index) => index)
              .map((value) => (
                <Skeleton key={value} variant="rounded" width="100%" height={70} />
              ))}
          </Grid>
        )}
        <Typography variant="body1" fontWeight={500} color={Colors.gray1} textAlign="center" mt={3}>
          {!hasNext && !loading && 'Não há dados a serem exibidos'}
        </Typography>
      </Grid>

      <DrawerFiltrarTransacao
        tipoTransacoes={TIPO_TRANSACAO_PJ_USER}
        open={openDrawerFiltroTransacao}
        onClose={closeDrawerFiltro}
        permitirFiltrarUsuarios
      />

      <DrawerDetalhesViagem bilhetes={bilhetesPassagem} onClose={() => setBilhetespassagem(null)} />
    </Grid>
  );
};

export default TransacoesUsuarios;
